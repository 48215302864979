import React from "react";
import StorageService from "../../services/StorageService";
import { withRouter } from "react-router-dom";
import {
  IonSlides,
  IonSlide,
  IonContent,
  withIonLifeCycle,
  IonAlert,
} from "@ionic/react";
import { connect } from "react-redux";
// import { HealthKit } from "@ionic-native/health-kit";
import { Health as HealthKit } from "@awesome-cordova-plugins/health";
import { App } from "@capacitor/app";
import { sendMessageOperation } from "../../duck/operations.js";
import AuthenticationService from "../../services/AuthenticationService";
import HomeService from "../../services/HomeService";
import MeditationCard from "./components/MeditationCard";
import HomePageHPCards from "./components/healthPlan";
import VitalSubmission2 from "./components/VitalSubmission2";
import ZylaTV from "./zylaTV";
import "./home-page.scss";
import ApiService from "../../services/ApiService";
import HomePageBlogs from "./components/HomePageBlogs";
import askGroup from "../../assets/images/ask-zyla-grouped.png";
import VitalStats from "./components/VitalStats";
import MembershipBanner from "./components/MembershipBanner";
import closeIcon from "../../assets/images/ic_close_white.png";
import CleverTapLogger from "../../clevertapLogger";
import TrackerService from "../../services/trackerServices";
import DailyTarget from "./components/DailyTargets";
import { IonPage } from "@ionic/react";
import moment from "moment";
import ZylaOffer from "./components/ZylaOffer";
import { FeatureCards } from "./components/FeatureCards";
import OfferCard from "./components/OfferCard";
import clockIcon from "../../assets/images/clock_icon_recipe.png";
import greenRecipeIcon from "../../assets/icons/green-recipe-icon-2.png";
import vegIcon from "../../assets/icons/veg.png";
import nonvegIcon from "../../assets/icons/non-veg.png";
import RecipeCard from "./components/RecipeCard";
import EventLoggerService from "../../services/EventLoggerService";
import { CircularProgress, Drawer } from "@mui/material";
import UserService from "../../services/UserService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import homeDiagnosticIcon from "../../assets/icons/home-testtube-icon.svg";
import homeExpertIcon from "../../assets/icons/home-stethescope-icon.svg";
import WalletCard from "../zylaWallet/components/WalletCard";
import WalletService from "../../services/WalletServices";
import WalletQuickLinkElement from "../zylaWallet/components/WalletQuickLinkElement";
import { redirectToWebWithToken } from "../../utils/helperMethods";
import { Device } from "@capacitor/device";

let swiper = null;
const slideOpts = {
  initialSlide: 0,
  speed: 500,
  autoplay: {
    delay: 5000,
  },
  on: {
    beforeInit() {
      swiper = this;
    },
  },
};

let programCard = {
  description:
    "<ul><li>Diagnosed Covid +ve and recovering at home</li> <li>SPO2>94 </li></ul>",
  name: "homepage card",
  duration: 30,
};

const redeemArr = [
  {
    id: 1,
    title: "Book Expert",
    subtitle: "Consultations",

    clickFunc: () => {
      redirectToWebWithToken(
        process.env.REACT_APP_zyla_WEBSITE + "teleconsult"
      );
    },
    image: homeExpertIcon,
  },
  {
    id: 2,
    title: "Book",
    subtitle: "Diagnostics",
    link: "/navigo",
    isNavigo: true,
    clickFunc: () => {
      redirectToWebWithToken(
        process.env.REACT_APP_zyla_WEBSITE +
          "full-body-test-health-checkup?utm_source=app_launcher&utm2=app"
      );
    },
    image: homeDiagnosticIcon,
  },
];

// const redeemArr = [
//   {
//     id: 1,
//     title: "Book Expert",
//     subtitle: " Consultations",
//     path: "",
//     icon: homeExpertIcon,
//   },
//   {
//     id: 2,
//     title: "Order ",
//     subtitle: "Medicines",
//     path: "",
//     icon: homeMedicineIcon,
//   },
//   {
//     id: 3,
//     title: "Book",
//     subtitle: "Diagnostics",
//     path: "",
//     icon: homeDiagnosticIcon,
//   },
// ];

class HomePage extends React.PureComponent {
  constructor(props) {
    super(props);
    // this.apiService = new ApiService();
    this.storage = new StorageService();
    this.authenticationService = new AuthenticationService();
    this.apiService = new ApiService();
    this.homeService = new HomeService();
    this.cleverTapLogger = new CleverTapLogger();
    this.trackerService = new TrackerService();
    this.eventLogger = new EventLoggerService();
    this.userService = new UserService();
    this.walletService = new WalletService();
    this.profileData = this.authenticationService.getUserProfile();
    this.state = {
      info: false,
      trackingEnabled: this.storage.get("tracking") !== null ? true : false,
      testimonials: {},
      videos: [],
      blogs: [],
      featureTiles: [],
      offerData: [],
      dynCard: this.storage.get("homepage-dynCard") === null ? true : false,
      accessCodeChanged: false,
      openRecipe: false,
      recipe: null,
      recipesImageURL: "",
      noDataAlert: false,
      openRecipeImage: false,
      sleepTime: 0,
      sleepTimerSet: false,
      hasError: false,
      errMsg: "No internet connection. Please try again",
      isWalletActive: false,
      walletLoader: true,
      walletInfo: {},
    };
    this.healthInterval = null;
  }

  componentDidMount() {
    this.storage.set("redDotHome", true);
    let profile = this.authenticationService.getUserProfile();
    let patientId = profile.patientProfile.id;

  
    App.addListener("appUrlOpen", (data) => {
      let patient = this.authenticationService.getUserProfile();

      this.userService.getLatestSettings(
        patient.patientProfile.id,
        (res) => {
          if (res.isChatVisible) {
            if (data.url === "https://app.zyla.in/HNyL") {
              this.props.history.push("/careTeam");
            } else if (data.url === "https://app.zyla.in/qh74") {
              this.props.history.push("/health-goals-screen");
            } else if (data.url === "https://app.zyla.in/1srt") {
              this.props.history.push("/health-plan");
            } else if (data.url === "https://app.zyla.in/ECQa") {
              this.props.history.push("/medicine");
            } else if (data.url === "https://app.zyla.in/3Pja") {
              this.props.history.push("/vitals");
            } else if (data.url === "https://app.zyla.in/6PA7") {
              this.props.history.push("/premium");
            } else if (data.url === "https://app.zyla.in/Zk8q") {
              this.props.history.push("/reporting");
            } else if (data.url === "https://app.zyla.in/TtnJ") {
              this.props.history.push("/documents");
            } else if (data.url === "https://app.zyla.in/h4bb") {
              this.props.history.push("/vitalsChart");
            } else if (data.url === "https://app.zyla.in/8u31") {
              this.props.history.push("/meditation");
            } else if (data.url === "https://app.zyla.in/eB8Y") {
              this.props.history.push("/dentalCare");
            } else if (data.url === "https://app.zyla.in/iwR9") {
              this.props.history.push("/trackingChart");
            } else if (data.url === "https://app.zyla.in/qAZp") {
              this.props.history.push("/devices");
            } else if (data.url === "https://app.zyla.in/K2FE") {
              this.props.history.push("/profile");
            } else if (data.url === "https://app.zyla.in/Hdio") {
              this.props.history.push("/tab/hamburger");
            } else if (data.url === "https://app.zyla.in/y1i9") {
              this.props.history.push("/family");
            } else if (data.url === "https://app.zyla.in/nuMn") {
            } else {
              this.props.history.push("/tab/chat");
            }
          }
        },
        (err) => {
          console.log("error is ", err);
        }
      );
    });

    this.homeService.getRecipeData(
      patientId,
      (res) => {
        this.setState({ recipe: res.recipes });
      },
      (err) => {
        console.log("cannot get the recipes", err);
      }
    );
    this.syncTrackerData()
      .then((res) => {
        this.stepAndSleepInitialize(res);
      })
      .catch((err) => {
        console.log("Permission not available");
      });

    this.getWalletInfo();
  }

  stepAndSleepInitialize = (syncResponse) => {


    Device.getInfo().then((r) => {
      if (r.platform === "ios") {
        HealthKit.available().then((available) => {
          console.log("available is ", available);
          if (available) {
            // Requesting permissions

            let options = {
              readTypes: [
                "HKQuantityTypeIdentifierStepCount",
                "HKCategoryTypeIdentifierSleepAnalysis",
              ],
            };

            HealthKit.requestAuthorization(options)
              .then((res) => {
                this.setState({ trackingEnabled: true });
                this.storage.set("trackingEnabled", true);
                Promise.allSettled([
                  this.syncStepData(syncResponse),
                  this.syncSleepData(syncResponse),
                ]).finally(() => {
                  this.loadSleepData();

                  this.loadHealthData();
                  this.healthInterval = setInterval(() => {
                    this.loadHealthData();
                  }, 30000);
                });
              })
              .catch((err) => console.log("Authorisation not granted", err));
          } else {
            alert("Healthkit is not available on this device");
          }
        });
      }
    });
    this.cleverTapLogger.push("open_home");
  };
  componentWillUnmount() {
    if (this.healthInterval !== null) {
      clearInterval(this.healthInterval);
    }
  }
  getWalletInfo = () => {
    this.walletService.getOrCreateWallet(
      (res) => {
        if (res && res.active) {
          this.setState({
            isWalletActive: true,
            walletLoader: false,
            walletInfo: { ...res },
          });
        } else {
          this.setState({ isWalletActive: false, walletLoader: false });
        }
      },
      (err) => {
        console.log("error while fetching wallet info", err);
        this.setState({ isWalletActive: false, walletLoader: false });
      }
    );
  };
  updateAccessCode = () => {
    this.setState({ accessCodeChanged: true });
  };

  syncStepData = (res) => {
    return new Promise((resolve, reject) => {
      if (
        new Date(res.lastSyncedSteps).toISOString() !==
        new Date(0).toISOString()
      ) {
        let currentDay = Date.now();
        let oneDayDuration = 24 * 60 * 60 * 1000;
        let lastSync = res.lastSyncedSteps;
        let ressteps = [];
        let patient = this.authenticationService.getUserProfile();
        let id = patient.patientProfile.id;

        let startDate = new Date(lastSync).setHours(0, 0, 0, 1);

        let stepOptions = {
          startDate: new Date(startDate),
          endDate: new Date(currentDay),
          aggregation: "day",
          sampleType: "HKQuantityTypeIdentifierStepCount",
          unit: "count",
        };

        HealthKit.querySampleTypeAggregated(stepOptions)
          .then((data) => {
            this.cleverTapLogger.pushEvent("aggregated_steps", {
              data: data,
            });

            data.map((ele) => {
              let obj = {
                recordedAt: new Date(
                  new Date(ele.startDate).setHours(12, 0, 0, 0)
                ).toISOString(),
                steps: Math.floor(ele.quantity),
              };
              ressteps.push(obj);
            });

            let params = {
              steps: ressteps,
            };
            this.trackerService.postSyncedSteps(
              id,
              params,
              (res) => {
                resolve();
              },
              (err) => {
                reject();
              }
            );
          })
          .catch((err) => {
            console.log("No steps: ", err);
            reject();
            this.cleverTapLogger.pushEvent("no_step_data");
          });
      } else {
        let currentDay = Date.now();
        let oneDayDuration = 24 * 60 * 60 * 1000;
        let lastSync = new Date(
          Date.now() - 7 * 24 * 60 * 60 * 1000
        ).toISOString();
        let ressteps = [];
        let patient = this.authenticationService.getUserProfile();
        let id = patient.patientProfile.id;

        let startDate = new Date(lastSync).setHours(0, 0, 0, 1);

        let stepOptions = {
          startDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
          endDate: new Date(currentDay),
          aggregation: "day",
          sampleType: "HKQuantityTypeIdentifierStepCount",
          unit: "count",
        };

        HealthKit.querySampleTypeAggregated(stepOptions)
          .then((data) => {
            this.cleverTapLogger.pushEvent("aggregated_steps", {
              data: data,
            });
            data.map((ele) => {
              let obj = {
                recordedAt: new Date(
                  new Date(ele.startDate).setHours(12, 0, 0, 0)
                ).toISOString(),
                steps: Math.floor(ele.quantity),
              };
              ressteps.push(obj);
            });

            let params = {
              steps: ressteps,
            };
            this.trackerService.postSyncedSteps(
              id,
              params,
              (res) => {
                resolve();
              },
              (err) => {
                reject();
              }
            );
          })
          .catch((err) => {
            console.log("No steps: ", err);
            this.cleverTapLogger.pushEvent("no_step_data");
            reject();
          });
      }
    });
  };

  getExactSLeepingTime = (data) => {
    data.sort((a, b) => a.startDate.localeCompare(b.startDate));

    if (data.length === 0) {
      return 0;
    }
    let startDate = data[0].startDate;
    let endDate = data[0].endDate;
    let totalTime = 0;
    for (let i = 1; i < data.length; i++) {
      if (moment(data[i].startDate).isSameOrBefore(endDate)) {
        if (moment(data[i].endDate).isAfter(endDate)) {
          endDate = data[i].endDate;
        }
      } else {
        const now = moment(startDate);
        const expiration = moment(endDate);

        // get the difference between the moments
        let diff = expiration.diff(now);

        let mins = diff / (1000 * 60);

        totalTime = totalTime + mins;
        startDate = data[i].startDate;
        endDate = data[i].endDate;
      }
    }
    const now = moment(startDate);
    const expiration = moment(endDate);

    // get the difference between the moments
    let diff = expiration.diff(now);

    let mins = diff / (1000 * 60);

    totalTime = totalTime + mins;

    return totalTime;
  };
  syncSleepData = (res) => {
    return new Promise((resolve, reject) => {
      if (
        new Date(res.lastSyncedSleep).toISOString() !==
        new Date(0).toISOString()
      ) {
        let oneDayDuration = 24 * 60 * 60 * 1000;
        let currentDay = Date.now() - oneDayDuration;
        let lastSync = res.lastSyncedSleep;
        let ressleep = [];
        let patient = this.authenticationService.getUserProfile();
        let id = patient.patientProfile.id;

        let sleepOptions = {
          startDate: new Date(
            new Date(lastSync).getTime() - 2 * 24 * 60 * 60 * 1000
          ),
          endDate: new Date(
            new Date(Date.now() + 2 * 24 * 60 * 60 * 1000).setHours(18, 0, 0, 0)
          ),
          sampleType: "HKCategoryTypeIdentifierSleepAnalysis",
        };

        HealthKit.querySampleType(sleepOptions)
          .then((data) => {
            let params = { data: data, lastSync: lastSync, id: id };
            // this.cleverTapLogger.pushEvent("sleep-sync", { data: data });
            this.trackerService.postSyncedSleep(
              id,
              params,
              (res) => {
                resolve();
              },
              (err) => {
                reject();
              }
            );
          })
          .catch((err) => {
            this.cleverTapLogger.pushEvent("sleep_sync_error", {
              error: err,
            });
            console.log("error !!! something went wrong ", err);
            reject();
          });
      } else {
        let oneDayDuration = 24 * 60 * 60 * 1000;
        let currentDay = Date.now() - oneDayDuration;
        let lastSync = new Date(
          Date.now() - 7 * 24 * 60 * 60 * 1000
        ).toISOString();
        let ressleep = [];
        let patient = this.authenticationService.getUserProfile();
        let id = patient.patientProfile.id;

        let sleepOptions = {
          startDate: new Date(new Date().getTime() - 10 * 24 * 60 * 60 * 1000),
          endDate: new Date(
            new Date(Date.now() + 2 * 24 * 60 * 60 * 1000).setHours(18, 0, 0, 0)
          ),
          sampleType: "HKCategoryTypeIdentifierSleepAnalysis",
        };

        HealthKit.querySampleType(sleepOptions)
          .then((data) => {
            let params = { data: data, lastSync: lastSync, id: id };
            this.cleverTapLogger.pushEvent("sleep-sync", { data: data });
            this.trackerService.postSyncedSleep(
              id,
              params,
              (res) => {
                resolve();
              },
              (err) => {
                reject();
              }
            );
          })
          .catch((err) => {
            this.cleverTapLogger.pushEvent("sleep_sync_error", {
              error: err,
            });
            console.log("error !!! something went wrong", err);
            reject();
          });
      }
    });
  };
  syncTrackerData = () => {
    return new Promise((resolve, reject) => {
      let patient = this.authenticationService.getUserProfile();
      let id = patient.patientProfile.id;
      this.trackerService.getLastSynced(
        id,
        (res) => {
          if (
            new Date(res.lastSyncedSteps).toISOString() ===
            new Date(0).toISOString()
          ) {
            reject();
          } else {
            resolve(res);
          }
        },
        (err) => {
          reject();
        }
      );
    });
  };

  ionViewDidEnter() {
    this.cleverTapLogger.push("open_home");
    let profile = this.authenticationService.getUserProfile();
    let patientId = profile.patientProfile.id;
    this.homeService.getHomePageData(
      patientId,
      (res) => {
        this.setState({
          testimonials: res.content.testimonials,
          videos: res.content.videos,
          blogs: res.content.blogs,
          featureTiles: res.content.featureTiles,
          offerData: res.content.carousel,
          info: true,
        });
      },
      (err) => {
        console.log("Some Error Ocuured while fetching the data", err);
      }
    );
  }
  hideDYNCard() {
    this.storage.set("homepage-dynCard", false);
    this.setState({ dynCard: false });
  }
  slideChange = () => {
    if (swiper && !swiper.autoplay.running) {
      swiper.autoplay.start();
    }
  };
  processSingleDayData = (data, stDate, enDate) => {
    let datainBed = [];
    let dataAsleep = [];
    for (let i = 0; i < data.length; i++) {
      let startDate = data[i].startDate;
      let endDate = data[i].endDate;
      if (
        (moment(startDate).isBefore(stDate) &&
          moment(endDate).isAfter(enDate)) ||
        (moment(startDate).isBefore(stDate) &&
          moment(endDate).isBefore(stDate)) ||
        (moment(startDate).isAfter(enDate) && moment(endDate).isAfter(enDate))
      ) {
        continue;
      }
      if (
        moment(startDate).isSameOrBefore(stDate) &&
        moment(endDate).isAfter(stDate) &&
        moment(endDate).isBefore(enDate)
      ) {
        let myData = { ...data[i] };
        myData.startDate = stDate;
        if (data[i].value === 0) {
          datainBed.push({ ...myData });
        } else {
          dataAsleep.push({ ...myData });
        }
      } else if (
        moment(startDate).isSameOrAfter(stDate) &&
        moment(endDate).isSameOrBefore(enDate)
      ) {
        if (data[i].value === 0) {
          datainBed.push({ ...data[i] });
        } else {
          dataAsleep.push({ ...data[i] });
        }
      } else if (
        moment(startDate).isSameOrAfter(stDate) &&
        moment(endDate).isSameOrAfter(enDate)
      ) {
        let myData = { ...data[i] };
        myData.endDate = enDate;
        if (data[i].value === 0) {
          datainBed.push({ ...myData });
        } else {
          dataAsleep.push({ ...myData });
        }
      } else {
      }
    }
    if (dataAsleep.length > 0) {
      return dataAsleep;
    }

    return datainBed;
  };
  loadSleepData = () => {
    let patient = this.authenticationService.getUserProfile();
    let id = patient.patientProfile.id;

    let sleepOptions = {
      startDate: new Date(
        new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000).setHours(
          18,
          0,
          0,
          0
        )
      ),
      endDate: new Date(
        new Date(Date.now() + 2 * 24 * 60 * 60 * 1000).setHours(18, 0, 0, 0)
      ),
      sampleType: "HKCategoryTypeIdentifierSleepAnalysis",
    };

    HealthKit.querySampleType(sleepOptions)
      .then((data) => {
        // this.cleverTapLogger.pushEvent("sleep_data", { data: data });
        // this.cleverTapLogger.pushEvent("sleep_data_rash", { data: data });
        let stDate, enDate;
        if (new Date().getHours() >= 18) {
          stDate = moment(new Date().setHours(18, 0, 0, 0)).format();
          enDate = moment(
            new Date(Date.now() + 24 * 60 * 60 * 1000).setHours(18, 0, 0, 0)
          ).format();
        } else {
          stDate = moment(
            new Date(Date.now() - 24 * 60 * 60 * 1000).setHours(18, 0, 0, 0)
          ).format();
          enDate = moment(new Date().setHours(18, 0, 0, 0)).format();
        }
        let realData = this.processSingleDayData(data, stDate, enDate);

        let totalTime = 0;

        totalTime = this.getExactSLeepingTime(realData);

        let recordingDate = new Date(
          new Date(Date.now()).setHours(12, 0, 0, 0)
        ).toISOString();
        if (new Date().getHours() >= 18) {
          recordingDate = new Date(
            new Date(Date.now() + 24 * 60 * 60 * 1000).setHours(12, 0, 0, 0)
          ).toISOString();
        }

        let param = {
          patientId: id,
          time: Math.round(totalTime),
          recordedAt: recordingDate,
        };

        // this.cleverTapLogger.pushEvent("sleep_data_total", { data: totalTime });
        this.trackerService.postSleepData(
          param,
          (response) => {
            this.setState({
              sleepTime: Math.round(totalTime),
              sleepTimerSet: true,
            });
          },
          (error) => {
            console.log("Something went wrong", error);
          }
        );
        if (totalTime === 0 && this.storage.get("sleep_popup") === null) {
          this.storage.set("sleep_popup", true);
          this.setState({ noDataAlert: true });
        }
      })
      .catch((err) => {
        this.cleverTapLogger.pushEvent("no_sleep_data");
      });
  };
  loadHealthData = () => {
    let patient = this.authenticationService.getUserProfile();
    let id = patient.patientProfile.id;

    let stepOptions = {
      startDate: new Date(new Date().setHours(0, 0, 0, 0)),
      endDate: new Date(),
      aggregation: "day",
      sampleType: "HKQuantityTypeIdentifierStepCount",
      unit: "count",
    };
    HealthKit.querySampleTypeAggregated(stepOptions)
      .then((data) => {
        let stepSum = data.reduce((a, b) => a + b.quantity, 0);

        let params = {
          patientId: id,
          steps: Math.floor(stepSum),
          recordedAt: new Date(
            new Date(Date.now()).setHours(12, 0, 0, 0)
          ).toISOString(),
        };

        // this.cleverTapLogger.pushEvent("step_data", { data: data });

        this.trackerService.postStepsData(
          params,
          (response) => {
            // this.cleverTapLogger.pusheventLogger("step_data_posted", {
            //   res: response,
            // });
          },
          (error) => {
            // this.cleverTapLogger.pushEvent("cannot post data", {
            //   err: error,
            // });
            console.log(error);
          }
        );
      })
      .catch((err) => {
        // this.cleverTapLogger.pushEvent("no_step_data");
      });
  };

  openRecipeDrawer = () => {
    this.setState({ openRecipe: true });
  };

  recipeSlideChange = (e) => {
    // console.log("I am recipe slide change ", e);
    // this.setState({ activeIndex: e.target.swiper.activeIndex });
  };

  closeRecipe = () => {
    this.setState({ openRecipe: false });
  };

  sendMesageRequest = (msg) => {
    const { sendMessageOperation } = this.props;
    let content = {
      message: {
        text: msg,
        type: 1,
        id: "" + new Date().getTime() + Math.random(),
      },
    };
    sendMessageOperation(content);

    if (this.props.zylaTabBtn && this.props.zylaTabBtn.current) {
      this.props.zylaTabButton.current.click();
    } else {
      this.props.history.push("/tab/chat");
    }
  };

  render() {
    const { uiConfig } = this.props;
    const { walletInfo } = this.state;
    let stateMessage = this.storage.get("stateMessage");

    return (
      <IonPage className="ion-tab-page">
        <IonContent style={{ "--background": "#F8F9FA" }}>
          <React.Fragment>
            <div className="home-page-wrapper">
              {this.state.hasError && (
                <div className="homepage-container-error-msg">
                  <span>{this.state.errMsg}</span>
                </div>
              )}
              <header>
                Hi, <span>{this.profileData.patientProfile.firstName}</span>
              </header>
              {!this.state.walletLoader && !this.state.isWalletActive && (
                <MembershipBanner stateMessage={this.props.uiConfig} />
              )}

              <div className="home-page-components responsive-600">
                {this.state.walletLoader && (
                  <CircularProgress
                    className="ion-router-circular-progress"
                    style={{ marginTop: "0px" }}
                  />
                )}
                {!this.state.walletLoader && !this.state.isWalletActive && (
                  <FeatureCards
                    zylaTabBtn={this.props.zylaTabButton}
                    isPremium={this.props.uiConfig}
                    featureCards={this.state.featureTiles}
                  />
                )}
                {!this.state.walletLoader && this.state.isWalletActive && (
                  <React.Fragment>
                    <WalletCard
                      name={walletInfo.name}
                      companyName={walletInfo.companyName}
                      expiryDate={walletInfo.expiryDate}
                      amount={walletInfo.amount}
                    />

                    <button
                      className="zyla-home-wallet-know-more-button"
                      onClick={() => {
                        this.props.history.push("/zylawallet");
                      }}
                    >
                      Know more <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                    <p className="zyla-home-wallet-redeem-heading">
                      Redeem now
                    </p>
                    <div className="zyla-home-wallet-redeem-wrapper">
                      {redeemArr.map((ele) => {
                        return <WalletQuickLinkElement ql={ele} key={ele.id} />;
                      })}
                    </div>
                  </React.Fragment>
                )}

                <OfferCard
                  patientDetails={this.props.uiConfig}
                  offerData={this.state.offerData}
                  sendMessage={this.sendMesageRequest}
                />

                <VitalStats CleverTapLogger={this.cleverTapLogger} />
                {window.Capacitor.platform !== "web" && (
                  <DailyTarget
                    trackingEnabled={this.state.trackingEnabled}
                    initializeStepAndSleep={this.stepAndSleepInitialize}
                    clevertap={this.cleverTapLogger}
                    sleepTime={this.state.sleepTime}
                    sleepTimerSet={this.state.sleepTimerSet}
                  />
                )}

                {window.Capacitor.platform === "web" && (
                  <div className="error_resolve_div">
                    <VitalSubmission2 CleverTapLogger={this.cleverTapLogger} />
                  </div>
                )}

                <MeditationCard
                  CleverTapLogger={this.cleverTapLogger}
                  stateMessage={stateMessage}
                />

                <HomePageHPCards CleverTapLogger={this.cleverTapLogger} />

                {!stateMessage.isPremium && !stateMessage.isZylaZen && (
                  <RecipeCard
                    openRecipeDrawer={this.openRecipeDrawer}
                    recipe={this.state.recipe}
                  />
                )}

                <ZylaOffer
                  updateAccessCode={this.updateAccessCode}
                  stateMessage={this.props.uiConfig}
                />
                <ZylaTV
                  CleverTapLogger={this.cleverTapLogger}
                  videos={this.state.videos}
                />

                {/* {stateMessage && !stateMessage.isPremium && (
                <>
                  <h1 id="banner-heading">Get more with zyla Premium</h1>

                  <div
                    className="home-page-banner"
                    id="personalized-care"
                    onClick={() => {
                      this.cleverTapLogger.push("Premium_CTA_Home");
                      this.props.history.push("/premium");
                    }}
                  >
                    <img className="right-arrow" src={RightArrow} />
                    <h2>Personalised Care</h2>
                    <p className="premium__text">
                      Get 24*7 personalized care management on your health
                      issues from the best medical experts.
                    </p>
                    <img
                      src={DoctorsAndNurses}
                      className="doctors-and-nurses"
                    />
                  </div>
                </>
              )} */}
                <HomePageBlogs
                  CleverTapLogger={this.cleverTapLogger}
                  blogs={this.state.blogs}
                />
              </div>
              {this.state.info && (
                <div className="homepage__slides responsive-600">
                  <IonSlides
                    // className="homepage__slides__progress__bar"
                    onIonSlideDidChange={this.slideChange}
                    pager={true}
                    mode="ios"
                    options={slideOpts}
                  >
                    {this.state.testimonials.map((testimony, index) => {
                      return (
                        <IonSlide key={index + 0}>
                          <div className="home__testimony__slide">
                            <div className="slide__review__text">
                              "{testimony.body}"
                            </div>
                            <div className="img__wrapper">
                              <div className="slide__img">
                                <img src={testimony.image} alt="image" />
                              </div>
                            </div>
                            <div className="slide__review__name">
                              {testimony.name} <span>({testimony.age})</span>
                            </div>
                          </div>
                        </IonSlide>
                      );
                    })}
                  </IonSlides>
                </div>
              )}
              <div className="ask-zyla-wrapper responsive-600">
                <div className="ask-zyla-container">
                  <div className="ask-zyla-left">
                    <img src={askGroup} />
                  </div>
                  <div className="ask-zyla-right">
                    <div className="ask-zyla-right-top">
                      <div className="top">
                        <span>Didn't find what you were looking for?</span>

                        <br />
                      </div>
                      <div className="bottom">
                        <span>Get answer to all your health questions.</span>
                      </div>
                    </div>
                    <div
                      className="ask-zyla-right-bottom"
                      onClick={() => {
                        // this.storage.set("scroll-chat", true);
                        this.cleverTapLogger.push("click_ask_zyla_home");
                        this.props.zylaTabButton.current.click();
                      }}
                    >
                      <div className="ask-zyla-button">
                        <span>Ask zyla</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
          {this.state.recipe && (
            <Drawer
              role="presentation"
              className="recipe__card__drawer"
              anchor="bottom"
              disableEnforceFocus
              open={this.state.openRecipe}
            >
              <div className="recipe-card-drawer-icon">
                <img src={greenRecipeIcon} />
                <span>Recipe</span>
              </div>
              <IonSlides
                className="recipe_drawer_ion_slides"
                onIonSlideDidChange={(e) => this.recipeSlideChange(e)}
                pager={true}
              >
                {this.state.recipe.map((recipes) => {
                  return (
                    <IonSlide>
                      <div className="recipe_drawer_container">
                        <div className="recipe_drawer_header">
                          <img
                            alt=""
                            src={recipes["image"]}
                            className="recipe_drawer_header_img"
                            onClick={() => {
                              this.setState({
                                openRecipeImage: true,
                                recipesImageURL: recipes.image,
                              });
                            }}
                          />
                          <img
                            alt=""
                            src={closeIcon}
                            className="recipe-close-icon"
                            onClick={() => this.closeRecipe()}
                          />
                          <div className="recipe_drawer_header_timing">
                            <img src={clockIcon} />
                            <div>{recipes.cooking_time} min</div>
                          </div>
                          <div className="recipe_drawer_header_cooking_tag">
                            Cooking time
                          </div>
                        </div>
                        <div className="recipe_name_div">
                          <div className="recipe_name_div_top">
                            <div>{recipes.name}</div>
                            <img
                              alt=""
                              src={
                                recipes.type === "veg" ? vegIcon : nonvegIcon
                              }
                            />
                          </div>
                          <div className="recipe_name_div_bottom"></div>
                        </div>
                        <div className="recipe_drawer_main">
                          <div className="recipe_drawer_main_ingredients">
                            <div className="recipe_drawer_main_ingredients_header">
                              <div className="fw-semibold">
                                Serving size: {recipes.serving_size}
                              </div>
                              <div className="fw-semibold">Ingredients: </div>
                            </div>
                            <ul className="recipe_drawer_list">
                              {recipes.ingredients.map((ing) => {
                                return (
                                  <li key={ing.id}>{`${
                                    ing.amount_min.length > 0
                                      ? ing.amount_min + " - "
                                      : ""
                                  }
                                    ${
                                      ing.amount_max +
                                      " " +
                                      ing.unit +
                                      " " +
                                      ing.name
                                    } ${
                                    ing.instruction.length > 0 ? "-" : ""
                                  } ${ing.instruction}`}</li>
                                );
                              })}
                            </ul>
                          </div>
                          <div className="recipe_drawer_main_ingredients">
                            <div className="fw-semibold">Method:</div>
                            <ul className="recipe_drawer_list ">
                              {recipes.method.split("\n").map((met) => {
                                return <li>{met}</li>;
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </IonSlide>
                  );
                })}
              </IonSlides>
              {this.state.openRecipeImage && (
                <div className="recipe-image-drawer-container">
                  <img
                    alt=""
                    src={this.state.recipesImageURL}
                    className="recipe-image-drawer-container-main-img"
                  />
                  <img
                    alt=""
                    src={closeIcon}
                    className="recipe-image-drawer-container-close-icon"
                    onClick={() => this.setState({ openRecipeImage: false })}
                  />
                </div>
              )}
            </Drawer>
          )}
          {this.state.noDataAlert && (
            <IonAlert
              className="no_data_alert"
              isOpen={this.state.noDataAlert}
              onDidDismiss={() => this.setState({ noDataAlert: false })}
              message={
                "In order to track your step/sleep data accurately, make sure that your schedule is updated on the Health app."
              }
              buttons={[
                {
                  text: "OK",
                  cssClass: "no_data_alert_ok",
                  handler: () => {
                    this.setState({ noDataAlert: false });
                  },
                },
              ]}
            />
          )}
        </IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uiConfig: state.chatReducer.uiConfig,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sendMessageOperation: (message) => {
      return dispatch(sendMessageOperation(message));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withIonLifeCycle(HomePage))
);
