import React, { Component } from "react";
import ContentLoader from "react-content-loader";

export default class DoctorLoading extends Component {
  render() {
    return (
      <div className="doctor-loading">
        <ContentLoader>
          <rect width="200" height="24" x="130" y="20" fill="#EDF1F7" rx="2" />
          <rect width="250" height="12" x="130" y="53" fill="#EDF1F7" rx="2" />
          <rect width="270" height="10" x="130" y="77" fill="#EDF1F7" rx="2" />
          <rect width="200" height="11" x="130" y="93" fill="#EDF1F7" rx="2" />
          <circle cx="55" cy="57" r="50" fill="#EDF1F7" />
        </ContentLoader>
      </div>
    );
  }
}
