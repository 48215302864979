import React from "react";
import UploadImage from "../../assets/images/upload.svg";
import RetryIcon from "../../assets/images/retry_error_icon.png";
import GreenCheckImage from "../../assets/images/ic_completed_success.png";
export const UploadPrescription = ({ openChooseFileTypePopup,isSizeError=false  }) => {
  return (
    <div
      className="doctor-prescription-section"
      onClick={openChooseFileTypePopup}
    >
      <div className="doctor-prescription-container">
        <div className="doctor-prescription-row">
          <div className="doctor-prescription-col-1">
            <img src={UploadImage} alt="icons"></img>
          </div>
          <div className="doctor-prescription-col-2">
          {isSizeError?<p className="error__text">
              Please retry! <br />
              File size should be <br />
              less than 20MB
            </p>:<p className="error__text">
              Please retry! <br />
              File could not be <br />
              uploaded
            </p>}
          </div>
        </div>
      </div>
    </div>
  );
};
export const UploadError = ({ openChooseFileTypePopup }) => {
  return (
    <div
      className="file__size__error"
      onClick={() => openChooseFileTypePopup()}
    >
      <div className="file-size-error-container">
        <div className="file-size-error-row">
          <div className="file-size-error-col-1">
            <img src={RetryIcon} className="error__img" alt="error-img"></img>
          </div>
          <div className="file-size-error-col-2">
            <p className="error__text">
              Please retry! <br />
              File could not be <br />
              uploaded
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ChangePrescription = ({ openChooseFileTypePopup }) => {
  return (
    <div className="prescription-successfull">
      <div className="prescription-successfull-container">
        <div className="prescription-successfull-container-row">
          <div className="prescription-successfull-container-col-1">
            <img src={GreenCheckImage} alt="icons"></img>
          </div>
          <div className="prescription-successfull-container-col-2">
            <p className="prescription-uploaded-text">
              Prescription Uploaded&nbsp;
            </p>

            <p
              className="prescription-uploaded-change"
              onClick={() => openChooseFileTypePopup()}
            >
              Change Prescription
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
