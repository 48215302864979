import React from "react";
import { IonInput } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { handleUpdateParamValues } from "../vitalSlice";
export default function VitalTableHeader({
  showParamDetail,
  parentIndex,
  groupModel,
  active,
}) {
  const platform = useSelector((store) => store.app.device.platform);

  const history = useHistory();
  const dispatch = useDispatch();

  const onChangeValue = (e, param, index) => {
    let value = e.target.value;

    if (e.detail.value == null) {
      value = "";
    }
    if (e.detail.value.length > 6) {
      value = e.detail.value.slice(0, -1);
    }
    dispatch(
      handleUpdateParamValues({
        paramUpdate: value,
        paramIndex: index,
        parentIndex: parentIndex,
      })
    );
  };

  return (
    <React.Fragment>
      <thead>
        <tr>
          <td className="vital-cell-type">{groupModel.name}</td>
          {active !== 2 && (
            <td className="vital-cell-input">
              {"Reading"}
              {`(${groupModel.unit})`}
            </td>
          )}
        </tr>
      </thead>

      <tbody>
        {groupModel.params &&
          groupModel.params.map((param, index) => {
            return (
              (active !== 2 || param.isRecommended) && (
                <tr key={param.id}>
                  <td
                    className={
                      param.isRecommended
                        ? " vital-cell-type active"
                        : "vital-cell-type"
                    }
                    onClick={() => showParamDetail(param)}
                  >
                    {param.name} {param.isRecommended ? "*" : ""}
                  </td>

                  {active !== 2 && (
                    <td className="vital-cell-input">
                      <IonInput
                        type="number"
                        inputmode="decimal"
                        readOnly={active}
                        maxlength="5"
                        name="value"
                        placeholder={groupModel.unit}
                        pattern="[0-9]+([\.][0-9]+)?"
                        onIonChange={(e) => {
                          if (e && e.detail && e.detail.value) {
                            onChangeValue(e, param, index);
                          }
                        }}
                        value={param.value}
                      ></IonInput>
                    </td>
                  )}
                </tr>
              )
            );
          })}
      </tbody>
    </React.Fragment>
  );
}
