import React from "react";
import "./home-page.scss";
import { IonButton } from "@ionic/react";
import { useDispatch } from "react-redux";
import { logEvent } from "../../app/eventSlice";

const ZylaTV = ({ videos }) => {
  const dispatch = useDispatch();
  return (
    <div className="zylatv-wrapper">
      <h1>Learn from experts</h1>
      {videos.map((video) => (
        <div
          key={video.id}
          className="zylatv-thumbnails"
          onClick={() => {
            dispatch(logEvent({ action: "click_play_video" }));

            window.open(
              `https://www.youtube.com/watch/${video.videoId}`,
              "_blank"
            );
          }}
        >
          <iframe
            className="thumbnail"
            src={`https://www.youtube.com/embed/${video.link}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
      ))}
      <IonButton
        expand="block"
        color="#fff"
        className="all-videos"
        onClick={() => {
          dispatch(logEvent({ action: "click_view_all_videos_Home" }));
          window.open(
            "https://www.youtube.com/playlist?list=PLEkiivFalO4IR9AjhaN46fyh1oZG8foYx",
            "_blank"
          );
        }}
      >
        View All Videos
      </IonButton>
    </div>
  );
};

export default ZylaTV;
