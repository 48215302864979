import FlagIndia from "../assets/images/flag_india.png";
import FlagUAE from "../assets/images/flag_uae.png";
import FlagUSA from "../assets/images/flag_usa.png";
import FlagUK from "../assets/images/flag_uk.png";
import FlagAus from "../assets/images/flag_australia.png";
import FlagCan from "../assets/images/flag_canada.png";
export const countries = [
  {
    name: "India",
    code: "91",
    id: "IN",
    maxLength: 10,
    currency: "INR",
    price: 2900,
    razorPayCode: "pl_GDh7XcSb0OELpz",
    currencySymbol: "₹",
    image: FlagIndia,
  },
  // {
  //   name: "UAE",
  //   code: "971",
  //   id: "AE",
  //   maxLength: 9,
  //   currency: "AED",
  //   price: 220,
  //   razorPayCode: "pl_GiHadsPjreNhnr",
  //   currencySymbol: "AED",
  //   image: FlagUAE,
  // },
  // {
  //   name: "USA",
  //   code: "1",
  //   id: "US",
  //   maxLength: 10,
  //   currency: "USD",
  //   price: 89,
  //   razorPayCode: "pl_GiHYVHEagksV6L",
  //   currencySymbol: "$",
  //   image: FlagUSA,
  // },
  // {
  //   name: "UK",
  //   code: "44",
  //   id: "GB",
  //   maxLength: 10,
  //   currency: "GBP",
  //   price: 89,
  //   razorPayCode: "pl_GiHccdkuQaRfWT",
  //   currencySymbol: "£",
  //   image: FlagUK,
  // },
  // {
  //   name: "Canada",
  //   code: "1",
  //   id: "CA",
  //   maxLength: 10,
  //   currency: "CAD",
  //   price: 89,
  //   razorPayCode: "pl_GiHbQyx1lAfXux",
  //   currencySymbol: "CA$",
  //   image: FlagCan,
  // },
  // {
  //   name: "Australia",
  //   code: "61",
  //   id: "AU",
  //   maxLength: 9,
  //   currency: "AUD",
  //   price: 89,
  //   razorPayCode: "pl_GiHZS5YwJWaxD6",
  //   currencySymbol: "A$",
  //   image: FlagAus,
  // },
];

export const allRoutes = {
  "/session-expired": true,
  "/assessment": true,
  "/health-plan": true,
  "/health-goals": true,
  "/health-goals-screen": true,
  "/profile": true,
  "/careTeam": true,
  "/reporting": true,
  "/vitals": true,
  "/dentalCare": true,
  "/documents": true,
  "/trackingChart": true,
  "/meditation": true,
  "/vitalsChart": true,
  "/medicine": true,
  "/premium": true,
  "/family": true,
  "/settings": true,
  "/address": true,
  "/navigo": true,
  "/zylawallet": true,
  "/privacy": true,
  "/terms": true,
};
