import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  IonItem,
  IonLabel,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import "../../assets/css/registration.scss";
import NextImage from "../../assets/images/ic_arrow_forward.png";
import CameraImage from "../../assets/images/camera2.png";
import DocumentImage from "../../assets/images/documents.png";
import ImageIconImage from "../../assets/images/images.png";
import DoctorLoading from "./DoctorLoading";
import DoctorCard from "./DoctorCard";
import InvalidDoctorCode from "./InvalidDoctorCode";
import { Dialog, DialogContent } from "@mui/material";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import userService from "../../services/UserService";
import documentService from "../../services/DocumentService";
import whiteBack from "../../assets/icons/arrow-back.png";
import {
  ChangePrescription,
  UploadError,
  UploadPrescription,
} from "./doctorCodeHelper";
import CameraPreviewComponent from "../common/cameraPreview";
import { handleBackButtonFunc } from "../../utils/helperMethods";
import PrepareUploadService from "../../services/PrepareUploadService";

export default function DoctorCodeComponent({
  closeDrawer,
  camera: cam,
  doctor: doc,
  file: fl,
  doctorCode: dc,
}) {
  const [active, setActive] = useState(dc ? true : false);
  const [doctorCode, setDoctorCode] = useState(dc);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [doctor, setDoctor] = useState(doc);
  const [fileChooser, setFileChooser] = useState(false);
  const [file, setFile] = useState(fl);
  const [camera, setCamera] = useState(cam);
  const [platform, setPlatform] = useState(window.Capacitor.platform);
  const [fileUploadError, setFileUploadError] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [myObj, setMyObj] = useState({});
  const [fileUploaded, setFileUploaded] = useState(false);
  const [openCameraPreview, setOpenCameraPreview] = useState(false);
  const [sizeError,setSizeError]=useState(false);
  let fileType = useRef(null);
  let inputFileRef = useRef(null);

  useEffect(() => {
    if (file || (camera && camera.file)) {
      uploadPrescription();
    }
  }, [file, camera]);

  const uploadPrescription = useCallback(() => {
    if (file || (camera && camera.file)) {
      let document = {
        id: 0,
        title: "",
        description: "",
        url: "",
        type: 4,
        format: 1,
        status: 4,
      };
      let type;
      if (!file) {
        if (camera.name === "jpg" || camera.name === "jpeg") {
          type = "image/jpg";
        } else if (camera.name === "png") {
          type = "image/png";
        }
      } else {
        type = file.type;
      }

      if (type === "image/jpg" || type === "image/jpeg") {
        document.format = 1;
      } else if (type === "image/png") {
        document.format = 2;
      } else if (type === "image/gif") {
        document.format = 3;
      } else if (type === "application/pdf") {
        document.format = 4;
      } else if (type === "audio/mp3") {
        document.format = 5;
      } else if (type === "video/mp4") {
        document.format = 6;
      } else {
        return;
      }
      if (file) {
        if (file.size > 20000000) {
          setFileUploadError(true);
          setActive(false);
          setUploading(false);
          return;
        }

        let objValues = {
          arg: 1,
          fdata: file.file,
          name: file.name,
          doc: document,
        };
        setMyObj({ ...objValues });
        setFileUploadError(false);
        setActive(false);
      } else {
        let fileName = Math.random() * (9999999 - 1111111) + 1111111;
        let name_temp = `${fileName}.${camera.name}`;
        let objValues = {
          arg: 2,
          fdata: camera.file,
          name: name_temp,
          doc: document,
        };
        setMyObj({ ...objValues });
        setFileUploadError(false);
        setActive(false);
      }
    } else {
      setFileUploadError(true);
      setActive(false);
      setUploading(false);
    }
  }, [file, camera]);
  const updateFetchedDoctorDetails = useCallback((doc, error) => {
    setDoctor(doc);
    setLoading(false);
    setLoaded(true);
    setError(error);
    setFile(undefined);
    setCamera(undefined);
  }, []);

  const searchDoctorCode = () => {
    setLoading(true);
    userService
      .searchDoctorCode(doctorCode)
      .then((res) => {
        updateFetchedDoctorDetails(res.data, false);
      })
      .catch((err) => {
        updateFetchedDoctorDetails({}, true);
      });
  };
  const openChooseFileTypePopup = () => {
    setFileChooser(true);
   
  };
  const onChangeDoctorCode = (e) => {
    if (e && e.target && e.target.value.length > 0) {
      setDoctorCode(e.target.value);
      setActive(true);
    }
  };
  const onChooseFileType = (type) => {
    
      if (type === "I" || type === "P") {
        if (window.Capacitor.platform !== "web") {
          if (type === "I") {
            PrepareUploadService.selectFiles(false)
              .then((res) => {
                onSelectFileToUpload(res.fileResponse,res.errors);
              })
              .catch((err) => {});
          } else {
            PrepareUploadService.pickFiles(false)
              .then((res) => {
                onSelectFileToUpload(res.fileResponse,res.errors);
              })
              .catch((err) => {});
          }
        } else {
          if (type === "I") {
            inputFileRef.current.accept =
              "image/jpg,image/jpeg,image/png,image/gif";
            inputFileRef.current.click();
          } else {
            inputFileRef.current.accept = "application/pdf";

            inputFileRef.current.click();
          }
        }
      }
     else if (type === "C") {
      takePicture();
      // setFileChooser(false);
      // setOpenCameraPreview(true);
    }
  };

  const takePicture = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      correctOrientation: false,
      source: CameraSource.Camera,
      resultType: CameraResultType.Uri,
    });

    if (image) {
      let fileExtension = image.format;
      setFile(undefined);
      setFileChooser(false);
      setFileUploaded(true);
      setCamera({ file: image, name: fileExtension });
    }
  };
  const onUploadCameraPreview = useCallback((fileReq) => {
    setFile(undefined);
    setFileChooser(false);
    setFileUploaded(true);
    setOpenCameraPreview(false);
    setCamera({ file: fileReq.file, name: "jpeg" });
  }, []);

  const onSelectFileToUpload = (e,errors={}) => {
    if(errors.sizeError){
      setFileUploadError(true);
      setActive(false);
      setUploading(false);
      setFileChooser(false)
      setSizeError(true);
      return;
    }
    const file = e.target.files[0];
    setFile(file);
    setFileChooser(false);
    setCamera(undefined);
    setFileUploaded(true);
    setFileUploadError(false);
    setSizeError(false)
    document.getElementById("input-file-dcc").value = null;
  };

  const documentUploadOnDone = async () => {
    setUploading(true);

    let file = myObj.fdata;
    if (myObj.arg === 2) {
      file = await fetch(myObj.fdata.webPath).then((r) => r.blob());
    }

    documentService
      .uploadDocument(file, myObj.name, myObj.doc, null, null)
      .then((res) => {
        const response = res.data;
        closeDrawer(true, {
          code: doctorCode,
          doctor: doctor,
          id: response.id,
        });
      })
      .catch((err) => {
        setFileUploadError(true);
        setUploading(false);
      });
  };

  const handleCameraClose = useCallback(() => {
    setOpenCameraPreview(false);
  }, []);

  return (
    <div className="doctor-code-container">
      <div
        className={`close-btn-wrapper ${
          window.Capacitor.platform === "web"
            ? "dialog-close-button-height-web"
            : ""
        }`}
        onClick={() => {
          closeDrawer(false, false);
        }}
      >
        <img src={whiteBack} alt="icon" />
        <span>Back</span>
      </div>
      <div>
        <div className="title">
          Add your doctor code to start your home support program.
        </div>
        <IonItem className="form-group mg-top">
          <IonLabel position="floating">Here is my doctor code</IonLabel>
          <IonInput
            placeholder="FSDJBCBS"
            value={doctorCode}
            autoComplete="off"
            disabled="false"
            id="doctor-code-input"
            onIonChange={(e) => onChangeDoctorCode(e)}
          ></IonInput>
          <div
            slot="end"
            className={
              active ? "next-button next-button-active" : "next-button"
            }
            onClick={() => searchDoctorCode()}
          >
            <img src={NextImage} alt="icon" />
          </div>
        </IonItem>
        {loading && <DoctorLoading></DoctorLoading>}
        {!loading && doctor && doctor.id && (
          <Fragment>
            <input
              ref={(ref) => (inputFileRef.current = ref)}
              hidden
              onChange={(e) => {
                onSelectFileToUpload(e);
              }}
              type="file"
              id="input-file-dcc"
            ></input>
            <DoctorCard doctor={doctor}></DoctorCard>
            <Dialog
              disableEnforceFocus
              open={fileChooser}
              onClose={() => setFileChooser(false)}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent
                className="text-center"
                style={{ paddingTop: "20px", paddingBottom: "20px" }}
              >
                <h4 style={{ marginTop: "0" }}>
                  Select the file type you want to upload.
                </h4>
                <IonGrid>
                  <IonRow>
                    {window.Capacitor.platform === "ios" && (
                      <IonCol onClick={() => onChooseFileType("C")}>
                        <img src={CameraImage} alt="icons"></img> <br />
                        Camera
                      </IonCol>
                    )}

                    <IonCol onClick={() => onChooseFileType("I")}>
                      <img src={ImageIconImage} alt="icons"></img>
                      <br />
                      Image
                    </IonCol>
                    <IonCol onClick={() => onChooseFileType("P")}>
                      <img src={DocumentImage} alt="icons"></img>
                      <br />
                      PDF
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </DialogContent>
            </Dialog>

            {!fileUploaded && (
              <UploadPrescription
                openChooseFileTypePopup={openChooseFileTypePopup}
              />
            )}
            {fileUploadError && (
              <UploadError openChooseFileTypePopup={openChooseFileTypePopup} isSizeError={sizeError} />
            )}
            {fileUploaded && !fileUploadError && (
              <ChangePrescription
                openChooseFileTypePopup={openChooseFileTypePopup}
              />
            )}
          </Fragment>
        )}
        {loaded && error && (
          <InvalidDoctorCode
            closeDrawer={() => closeDrawer(false, true)}
          ></InvalidDoctorCode>
        )}
      </div>

      <footer className="text-center">
        <button
          className="doctor-document-upload-done"
          disabled={!fileUploaded || fileUploadError || uploading || loading}
          onClick={(e) => {
            documentUploadOnDone();
          }}
        >
          Done
        </button>
      </footer>
      {openCameraPreview && (
        <div className="test-div-new-chat">
          <CameraPreviewComponent
            onCloseCamera={handleCameraClose}
            onUploadDone={onUploadCameraPreview}
            doNotUpload={true}
          />
        </div>
      )}
    </div>
  );
}
