import React, { Component, Fragment } from "react";

export default class InvalidDoctorCode extends Component {
  render() {
    const { closeDrawer } = this.props;
    return (
      <Fragment>
        <div className="code-error">
          <div className="code-error-detail">
            Please enter valid doctor code
          </div>
        </div>
        <div
          onClick={() => closeDrawer()}
          className="text-center dont-have-code color-blue"
        >
          I don't have a doctor code
        </div>
      </Fragment>
    );
  }
}
