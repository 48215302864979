import { MenuItem, Select } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import arrowLeft from "../../../assets/icons/arrow-back-black.svg";
import nextImg from "../../../assets/images/next.png";
import nextGrayImg from "../../../assets/images/next_grey.png";

import familyPlanService from "../../../services/FamilyPlanServices";
import { countryCodeArray } from "../../../utils/common";
import { openFamilyProfile, updateErrorState } from "../familySlice";
import { useHistory } from "react-router";

function AddFamilyMember({ closeDialog }) {
  const [mobile, setMobile] = useState("");

  const [countryCodeClone, setCountryCodeClone] = useState("IN");
  const [countryCode, setCountryCode] = useState("91");

  const [relation, setRelation] = useState("-1");
  const [showOTP, setShowOTP] = useState(false);

  const [otp, setOTP] = useState("");
  const [otpTimer, setOTPTimer] = useState(-1);
  const [seconds, setSeconds] = useState(60);
  const [resendVisible, setResendVisible] = useState(false);
  const familyStore = useSelector((store) => store.familyInfo);
  const { relations } = familyStore;

  const dispatch = useDispatch();
  const history = useHistory();
  const timerRef = useRef(null);

  const changeMobile = (e) => {
    setMobile(e.target.value);
  };
  const handleError = (data, msg, isPos) => {
    dispatch(
      updateErrorState({ data: data, msg: msg, isErrorPositive: isPos })
    );
  };

  const handleOTPFunc = (resend = false) => {
    let patternUAE = /^[0-9]{9}$/;
    let patternOthers = /^[0-9]{10}$/;
    if (countryCode === 971) {
      if (!patternUAE.test(mobile)) {
        handleError(true, "Please enter a valid UAE number", false);
        return;
      }
    } else {
      if (!patternOthers.test(mobile)) {
        handleError(true, "Please enter a valid number", false);
        return;
      }
    }
    if (relation === "-1") {
      handleError(true, "Please select a valid relation.", false);
      return;
    }

    let params = {
      phoneNo: parseInt(mobile),
      countryCode: parseInt(countryCode),
      memberRelation: parseInt(relation),
    };
    familyPlanService
      .getFamilyOTP(params)
      .then((res) => {
        setShowOTP(true);
        setOTPTimer(1);
        setResendVisible(false);
        if (resend) {
          handleError(
            true,
            "A new OTP has been sent to your mobile number.",
            true
          );
        }
      })
      .catch((err) => {
        handleError(true, err.response.data.message, false);
      });
  };
  useEffect(() => {
    if (otpTimer !== -1) {
      setOTPTimer(-1);
      setSeconds(60);
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      timerRef.current = setInterval(() => {
        setSeconds((seconds) => {
          return seconds - 1;
        });
      }, 1000);
    }
  }, [otpTimer]);

  useEffect(() => {
    if (seconds === 0) {
      setResendVisible(true);

      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    }
  }, [seconds]);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  const handleResendClick = () => {
    handleOTPFunc(true);
  };

  const handleContinue = () => {
    if (timerRef.current) {
      //  clearInterval(timerRef.current);
      //timerRef.current = null;
    }
    let otpPattern = /^[0-9]{5}$/;
    if (!otpPattern.test(otp)) {
      handleError(true, "Please enter a valid OTP", false);
      return;
    }
    let params = {
      otp: parseInt(otp),
      phoneNo: parseInt(mobile),
      countryCode: parseInt(countryCode),
      memberRelation: parseInt(relation),
      otpTimeLeft: parseInt(timerRef.current),
    };
    familyPlanService
      .verifyFamilyOTP(params)
      .then((res) => {
        dispatch(openFamilyProfile(res.data));
      })
      .catch((err) => {
        handleError(true, "Cannot validate OTP.", false);
      });
  };
  const handleContinueMinor = () => {
    let res = {
      memberCountryCode: countryCode,
      memberRelation: relation,
    };
    dispatch(openFamilyProfile(res));
  };

  return (
    <div className="add-family-member-container">
      <div className="add-family-member-header">
        <img
          src={arrowLeft}
          onClick={() => {
            closeDialog(false);
          }}
          alt="icon"
          className="w-3 h-3"
        />
      </div>
      <div className="add-family-member-body">
        <div className="add-family-body-relation-section">
          <p>
            Relation<span className="required-mark-class">*</span>
          </p>
          <Select
            value={relation}
            disabled={showOTP}
            onChange={(e) => {
              if (e.target.value === 6 || e.target.value === 8) {
                setMobile("");
              }
              setRelation(e.target.value);
            }}
            className="add-family-body-relation-section-select"
            variant="standard"
          >
            <MenuItem disabled value="-1">
              Add Relation
            </MenuItem>
            {relations &&
              relations.ids &&
              relations.ids.map((rel) => {
                return (
                  <MenuItem
                    value={relations.entities[rel].code}
                    key={relations.entities[rel].code}
                  >
                    {relations.entities[rel].value}
                  </MenuItem>
                );
              })}
          </Select>
        </div>
        <div className="add-family-body-information-section-parent">
          <p className="add-family-body-information-section-heading">
            Mobile number
            {!(relation === 6 || relation === 8) && (
              <span className="required-mark-class">*</span>
            )}
          </p>
          <div className="add-family-body-information-section">
            <Select
              className="add-family-body-information-section-select"
              value={countryCodeClone}
              disabled={showOTP}
              id="add-family-country-select"
              variant="standard"
              labelId="add-family-country-select-label"
              onChange={(e) => {
                let cc = countryCodeArray.find(
                  (country) => country.id === e.target.value
                );
                let countryCo = "91";
                if (cc) {
                  countryCo = cc.code;
                }
                setCountryCodeClone(e.target.value);
                setCountryCode(countryCo);
              }}
            >
              {countryCodeArray.map((country) => {
                return (
                  <MenuItem
                    key={country.id}
                    value={country.id}
                    className="country-menu-item-class"
                  >
                    <img src={country.image} alt="icon" />
                    <span>+{country.code}</span>
                  </MenuItem>
                );
              })}
            </Select>
            <input
              type="tel"
              value={mobile}
              maxLength={10}
              disabled={relation === 6 || relation === 8 || showOTP}
              placeholder={
                relation === 6 || relation === 8
                  ? "333XXXXXXX"
                  : "Mobile Number"
              }
              onChange={changeMobile}
              className="add-family-body-information-section-input"
            />
            {!(relation === 6 || relation === 8) && (
              <img
                src={mobile.length >= 9 && !showOTP ? nextImg : nextGrayImg}
                className="add-family-body-information-section-nextIMG"
                alt="icon"
                onClick={() => {
                  if (!showOTP) {
                    handleOTPFunc();
                  }
                }}
              />
            )}
          </div>
        </div>
        {showOTP && (
          <div className="add-family-body-otp-section">
            <p className="add-family-body-otp-section-heading">
              OTP<span className="required-mark-class">*</span>
            </p>
            <input
              type="text"
              maxLength={5}
              value={otp}
              onChange={(e) => {
                setOTP(e.target.value);
              }}
              className="add-family-body-otp-section-input"
              placeholder="Enter OTP"
            />
            <div className="add-family-otp-msg">
              <div className="add-family-otp-msg-2">
                <span>
                  {`If you do not receive the OTP in ${seconds} seconds,`}
                </span>
                <br />
                <span>click</span>
                <span
                  className={`${
                    resendVisible ? "text-underline-class" : ""
                  } otp-msg-resend-button`}
                  onClick={() => {
                    if (resendVisible) {
                      handleResendClick();
                    }
                  }}
                >
                  <span className="underline">resend</span>
                </span>
              </div>
            </div>
          </div>
        )}
        <div className="add-family-last-section">
          {relation === 6 || relation === 8 ? (
            <button
              className="add-family-last-section-continue"
              onClick={handleContinueMinor}
            >
              Continue
            </button>
          ) : (
            <button
              className="add-family-last-section-continue"
              disabled={otp.length <= 4 || relation === ""}
              onClick={handleContinue}
            >
              Continue
            </button>
          )}

          <p className="add-family-last-section-desc">
            By clicking continue, you agree to our
            <br />
            <strong
              onClick={() => {
                history.push("/terms");
              }}
            >
              Terms of service
            </strong>
            &nbsp; & &nbsp;
            <strong
              onClick={() => {
                history.push("/privacy");
              }}
            >
              Privacy policy.
            </strong>
          </p>
        </div>
      </div>
    </div>
  );
}

export default AddFamilyMember;
