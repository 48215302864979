import { IonAlert, IonContent, IonPage } from "@ionic/react";
import React, { useEffect } from "react";
import { MyPlan3 } from "./components/MyPlan3";
import { MyRewardPoint } from "./components/MyRewardPoint";
import { SliderImage } from "./components/SliderImage";
import { BookingNow } from "./components/booking";
import MyPlan from "./components/myPlan";
import { MyVitals } from "./components/myVital";
import HomePageHPCards from "./components/healthPlan";
import "./home-page.scss";
import HomepageMeditation from "./components/MeditationCard";
import { MyHealthyWeek } from "./components/myHealthyWeek";
import { useDispatch, useSelector } from "react-redux";
import { fetchBannerData, homePlanData } from "./homePageSlice";

import { App } from "@capacitor/app";
import Steps from "./components/StepsTracker";

const MliHomePage = ({ shouldShowExit, setShouldShowExit }) => {
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.login.clientId);
  const homeStore = useSelector((state) => state.home);

  const patientProfile = useSelector(
    (state) => state.login.user.patientProfile
  );
  useEffect(() => {
    dispatch(homePlanData(clientId));
    dispatch(fetchBannerData(patientProfile.id));
  }, []);

  const { bannerImages } = homeStore;
  const { id } = patientProfile;

  return (
    <IonPage>
      <IonContent>
        <div className="px-5">
          <SliderImage bannerData={bannerImages} id={id} />
          <BookingNow />

          <MyRewardPoint />

          <MyVitals />
          {/* {window.Capacitor.platform !== "web" && <MyVitals />} */}
          {/* <Steps /> */}

          <MyPlan />
          {/* <MyPlan3 /> */}

          <HomePageHPCards />
          <HomepageMeditation />
        </div>
        {shouldShowExit && (
          <IonAlert
            className="no_data_alert"
            isOpen={shouldShowExit}
            onDidDismiss={() => setShouldShowExit(false)}
            message={"Do you want to exit the app ?"}
            buttons={[
              {
                text: "Cancel",
                cssClass: "no_data_alert_ok",
                handler: () => {
                  setShouldShowExit(false);
                },
              },
              {
                text: "OK",
                cssClass: "no_data_alert_ok",
                handler: () => {
                  setShouldShowExit(false);
                  App.exitApp();
                },
              },
            ]}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default MliHomePage;
