import React, { Fragment, useCallback, useEffect, useState } from "react";
import Media from "./media";
export default function MeditationSectionComponent({
  meditationMedia,
  token,
  isRecomShow,
  meditateNow,
  closeRecommended,
}) {
  const [current, setCurrent] = useState(undefined);
  const [meditationMediaState, setMeditationMediaState] = useState([]);
  const [mediaMap, setMediaMap] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  useEffect(() => {
    updateState();
  }, [meditationMedia]);

  useEffect(() => {
    if (isRecomShow) {
      closeRecommended(isPlaying);
    }
  }, [isPlaying]);

  const updateState = () => {
    let mediaMap = {};
    meditationMedia.map((media, index) => {
      mediaMap[media.id] = {
        previous: getPreviousMedia(meditationMedia, index),
        next: getNextMedia(meditationMedia, index),
      };
    });
    setMeditationMediaState(meditationMedia);
    setMediaMap(mediaMap);
  };

  const getPreviousMedia = useCallback((meditationMedia, index) => {
    if (index === 0) {
      return null;
    }
    return meditationMedia[index - 1].id;
  }, []);
  const getNextMedia = useCallback((meditationMedia, index) => {
    if (index === meditationMedia.length - 1) {
      return null;
    }
    return meditationMedia[index + 1].id;
  }, []);

  const togglePlay = (media) => {
    let mediaId = isPlaying ? undefined : media.id;
    setCurrent(mediaId);
    setIsPlaying((prevState) => !prevState);
  };
  const onPrevious = (med) => {
    if (mediaMap[med.id].previous) {
      setCurrent(mediaMap[med.id].previous);
    }
  };
  const onNext = (med) => {
    if (mediaMap[med.id].next) {
      setCurrent(mediaMap[med.id].next);
    }
  };
  return (
    <div>
      {meditationMediaState.map((media, index) => {
        return (
          <Fragment key={media.id}>
            <Media
              media={media}
              isPlaying={isRecomShow ? meditateNow : current === media.id}
              togglePlay={(m) => togglePlay(m)}
              onPrevious={(med) => onPrevious(med)}
              onNext={(med) => onNext(med)}
              isNext={mediaMap[media.id].next}
              isPrevious={mediaMap[media.id].previous}
              token={token}
              isRecomShow={isRecomShow}
            />
          </Fragment>
        );
      })}
    </div>
  );
}
