import React, { Fragment, useEffect } from "react";
import Meditation from "./components/meditation";
import "./index.scss";
import { IonContent, IonPage, IonSpinner } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import MeditationHeaderImage from "../../assets/images/meditation-header.png";
import BackButton from "../../components/common/BackButton";
import { Divider } from "@mui/material";
import { logEvent } from "../../app/eventSlice";
import { useHistory } from "react-router";
import { getAllMeditation, toggleMeditateNow } from "./meditationSlice";

export default function MeditationMain() {
  const paytmFlag = useSelector((store) => store.app.device.isPaytm);
  const sm = useSelector((store) => store.tab.uiConfig);
  const token = useSelector((store) => store.tab.docToken?.token);
  const { meditationMedia, recommendedMediVideo, ui } = useSelector(
    (store) => store.meditation
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, recomededloaded, meditateNow } = ui;

  useEffect(() => {
    dispatch(logEvent({ action: "open_meditation" }));
    dispatch(getAllMeditation({ isPremium: sm.isPremium }));
  }, []);

  const closeRecommended = (data) => {
    dispatch(toggleMeditateNow(data));
  };

  return (
    <div>
      {loading ? (
        <div className="loading-spinner-holder">
          <IonSpinner></IonSpinner>
        </div>
      ) : (
        <IonPage>
          <IonContent className="meditation-container">
            <Fragment>
              {!paytmFlag && (
                <div className="meditation-page-head">
                  <BackButton
                    history={history}
                    ismeditate={true}
                    onClick={() =>
                      dispatch(
                        logEvent({ action: "click_close_meditation_module" })
                      )
                    }
                  />
                  <div className="fwbold meditation-heading">Meditations</div>
                </div>
              )}
              <Divider />
              <div className="meditation-body">
                <div className="meditaion-header">
                  Regular meditation reduces stress & improves sleep by 80%.
                  <button
                    onClick={() => {
                      dispatch(toggleMeditateNow(true));
                      dispatch(
                        logEvent({
                          action: "click_recommended_meditation_home",
                        })
                        // console.log("click_recommended_meditation_home")
                      );
                    }}
                  >
                    Meditate now
                  </button>
                  <img src={MeditationHeaderImage} alt="bg-img" />
                </div>
                {sm && sm.isPremium && <h4>Recommended</h4>}
                {recomededloaded && sm && sm.isPremium ? (
                  <Meditation
                    meditationMedia={recommendedMediVideo}
                    token={token}
                    meditateNow={meditateNow}
                    closeRecommended={closeRecommended}
                    isRecomShow={true}
                  />
                ) : null}

                <h4>All</h4>
                {sm && !sm.isPremium && (
                  <Meditation
                    meditationMedia={recommendedMediVideo}
                    token={token}
                    meditateNow={meditateNow}
                    closeRecommended={closeRecommended}
                    isRecomShow={true}
                  />
                )}
                <Meditation meditationMedia={meditationMedia} token={token} />
              </div>
            </Fragment>
          </IonContent>
        </IonPage>
      )}
    </div>
  );
}
