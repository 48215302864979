import { IonContent, IonInput, IonPage, IonDatetime } from "@ionic/react";
import React, { useEffect, useState, useRef } from "react";
import nextButton from "../../../assets/images/next.png";
import nextButtonGrey from "../../../assets/images/next_grey.png";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CountryModal from "../../../components/common/countryModal";
import {
  getOTP,
  toggleShowOTP,
  updateErrorState,
  verifyOTP,
} from "../loginSlice";
import validation from "../validation";
import { useDispatch, useSelector } from "react-redux";
import { setDefaultCountry } from "../../../app/appSlice";
import { logEvent, logEventWithData } from "../../../app/eventSlice";
import TermsCondition from "./termsCondition";

import loginImage from "../../../assets/images/loginPage.png";
import MaxLogo from "../../../assets/images/max.svg";
import moment from "moment";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import useInterval from "../../../components/common/useInterval";
import arrowBlack from "../../../assets/icons/arrow-back-black.svg";
import calendarIcon from "../../../assets/icons/calendar-icon.svg";
const DEFAULT_TIME = 60;
const labelStyle = {
  color: "#171A21",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "20px",
};
const spanStyle = {
  color: "#E44437",
};
const heading = {
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "22.4px",
  color: "#171A21",
};
const textStyle = {
  fontWeight: "500",
  fontSize: "12px",
  lineHeight: "15.6px",
  color: "#26284D80",
};
const style = {
  fontWeight: "300",
  fontSize: "10px",
  lineHeight: "14px",
  color: "#464646",
};
export default function LoginPhone({ defaultPhoneNo, country }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [countryState, setCountryState] = useState(country);
  const [phoneNo, setPhoneNo] = useState(defaultPhoneNo);
  const [dob, setDob] = useState("");
  const [showCountryModal, setShowCountryModal] = useState(false);
  const [btnActive, setBtnActive] = useState(false);
  const [otp, setOtp] = useState("");
  const [time, setTime] = useState(DEFAULT_TIME);
  const [showTerm, setShowTerm] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const loginStore = useSelector((state) => state.login);
  const [maxDateRef, setMaxDateRef] = useState(moment().format("YYYY-MM-DD"));
  const [minDateRef, setMinDateRef] = useState(
    moment().subtract(100, "years").format("YYYY-MM-DD")
  );

  const calendarRef = useRef(null);
  const { ui } = loginStore;
  const { loading } = ui;

  useEffect(() => {
    setCountryState(country);

    if (defaultPhoneNo) {
      setPhoneNo(defaultPhoneNo);
      setBtnActive(defaultPhoneNo.length === country.maxLength ? true : false);
    }
  }, [country, defaultPhoneNo]);
  useEffect(() => {
    if (ui.startTimer) {
      setTime(60);
    }
  }, [ui.startTimer]);
  useInterval(() => {
    if (ui.startTimer && time >= 1) {
      setTime(time - 1);
    }
  }, 1000);
  const onChangePhoneInput = (v) => {
    setPhoneNo(v);

    if (v) {
      if (v.length === 1) {
        dispatch(logEvent({ action: "quick_signup_click_edit_phone_number" }));
      }
    } else {
      setBtnActive(false);
    }
  };
  useEffect(() => {
    setBtnActive(phoneNo && dob);
  }, [phoneNo, dob]);

  const onClickSubmit = (isResend = false) => {
    const isValidPhoneNo = validation.validatePhoneNo(
      phoneNo,
      countryState.code
    );

    if (isValidPhoneNo) {
      const phoneNumber = phoneNo.toString();
      dispatch(
        getOTP({
          phoneNo: `${phoneNo}`,
          countryCode: countryState.code,
          dob: moment(dob, "Do MMMM YYYY").format("DD-MMM-YYYY").toUpperCase(),
          isResend: isResend,
        })
      );
    } else {
      dispatch(
        updateErrorState({
          data: true,
          msg: "Please enter a valid phone number",
          isPositive: false,
        })
      );
    }
  };
  const onChangeInput = (v) => {
    setOtp(v);

    if (v.length === 1) {
      dispatch(logEvent({ action: "enter_otp" }));
    }
  };
  const onClickVerifyOtp = () => {
    if (validation.validateOTP(otp)) {
      dispatch(logEvent({ action: "verify_otp" }));

      dispatch(verifyOTP({ user: { ...loginStore.user, otp: otp }, dob: dob }));
    } else {
      dispatch(
        updateErrorState({ data: true, msg: "Please enter a valid otp" })
      );
    }
  };
  const handleIonDateTimeOpener = () => {
    if (
      window.Capacitor.platform === "ios" ||
      window.Capacitor.platform === "web" ||
      window.Capacitor.platform === "android"
    ) {
      if (calendarRef && calendarRef.current) {
        if (!calendarRef.current.value) {
          const defaultDate = moment("2000-01-01").format("YYYY-MM-DD");
          calendarRef.current.value = defaultDate;
          setDob(moment(defaultDate).format("Do MMMM YYYY"));
        }

        calendarRef.current.open();
      }
    }
  };

  return (
    <div className="px-5">
      <div className="logo -mx-5">
        <div className="flex items-center ml-5">
          {ui.showOtp && (
            <img
              src={arrowBlack}
              className="w-6 h-6"
              onClick={() => {
                dispatch(toggleShowOTP(false));
              }}
            />
          )}
          <div className="flex-1 flex justify-center mr-5">
            <img
              src={MaxLogo}
              alt="image"
              className=""
              style={{
                height: "64px",
                width: "91px",
                marginTop: "32px",
                display: "flex",
                margin: "auto",
                justifyContent: "center",
              }}
            />
          </div>
        </div>

        <img src={loginImage} alt="image" className="w-full" />
      </div>

      <div className="input mt-4">
        <div className="font-bold text-base leading-6 login-phone-mobile-container">
          Login using your registered mobile number
        </div>
        <label
          for="mobile"
          className="login-phone-mobile-container"
          style={labelStyle}
        >
          Mobile number<span style={spanStyle}>*</span>
        </label>
        <CountryModal
          onChange={(d) => {
            setCountryState(d);
            dispatch(setDefaultCountry(country));
            setShowCountryModal(false);
          }}
          onClose={() => {
            setShowCountryModal(false);
          }}
          showModal={showCountryModal}
        ></CountryModal>
        <div className="login-phone-mobile-container ">
          <div className="input-wrapper">
            <button
              className="text-sm bg-white flex-initial leading-7 flex   items-center justify-between w-24 mr-1 pl-0 "
              onClick={() => setShowCountryModal(true)}
            >
              <img
                className="w-6"
                src={countryState.image}
                alt={countryState.name}
              />

              {window.Capacitor.platform === "ios" ? (
                <span className="text-lightblack">+{countryState.code}</span>
              ) : (
                <span className="text-lightblack">+{countryState.code}</span>
              )}
              <span>
                <FontAwesomeIcon icon={faChevronDown} color="#d8d8d8" />
              </span>
            </button>

            <IonInput
              className="flex-1  input-no-steps  text-base "
              name="phoneNo"
              type="tel"
              inputmode="tel"
              pattern="tel"
              placeholder="Mobile number"
              value={phoneNo}
              maxlength={countryState.maxLength}
              size={countryState.maxLength}
              onIonChange={(e) => {
                onChangePhoneInput(e.target.value);
              }}
            ></IonInput>
          </div>
        </div>
        <div className="baby-dob-container">
          <p className="baby-dob-string ml-0">
            Date of birth<span style={spanStyle}>*</span>
          </p>
          <div className="baby-dob-container-bottom">
            {dob ? (
              <p
                className="baby-dob-date !text-base"
                onClick={handleIonDateTimeOpener}
              >
                {dob}
              </p>
            ) : (
              <p
                className="baby-dob-placeholder"
                onClick={handleIonDateTimeOpener}
              >
                {"dd-mm-yyyy"}
              </p>
            )}
            <img src={calendarIcon} onClick={handleIonDateTimeOpener} />
          </div>
        </div>
        <IonDatetime
          className="my-ion-date-time-class"
          ref={calendarRef}
          displayFormat="DD/MMM/YYYY"
          mode="ios"
          monthShortNames="Jan, Feb, Mar, Apr, May, June, July, Aug, Sep, Oct, Nov, Dec"
          style={{ display: "none" }}
          onIonChange={(e) => {
            setDob(moment(e.detail.value).format("Do MMMM YYYY"));
          }}
          max={maxDateRef}
          min={minDateRef}
        />
        {loading && !ui.showOtp && (
          <span className="text-[10px] leading-[12px] font-normal font-montserrat text-[#171A2180]">
            Phone number authentication in process. Please do not click on back
            button or close the app.
          </span>
        )}

        {ui.showOtp && (
          <>
            <div className="pt-3 margin-left-13">
              <label htmlFor="otp" style={labelStyle}>
                OTP <span style={spanStyle}>*</span>
              </label>
            </div>

            <div className="otp-input-wrapper">
              <IonInput
                name="otp"
                className=""
                placeholder="xxxxx"
                type="otp"
                inputmode="otp"
                value={otp}
                maxlength="5"
                size="5"
                onIonChange={(e) => {
                  onChangeInput(e.target.value);
                }}
              ></IonInput>
            </div>
            <div
              className="my-1 text-xs text-left login-phone-mobile-container"
              style={{ color: "#26284D80" }}
            >
              {time !== 0 && (
                <span className="text-[10px] leading-[12px] font-normal font-montserrat text-[#171A2180]">
                  Didn’t receive OTP? Request for new OTP in 00:{time} &nbsp;
                </span>
              )}
              {time === 0 && (
                <>
                  <span>Didn’t receive OTP? Click</span>&nbsp;
                  <span
                    className="font-semibold underline"
                    style={{ color: "#F47D35" }}
                    onClick={() => onClickSubmit(true)}
                  >
                    resend.
                  </span>
                </>
              )}
            </div>
          </>
        )}
      </div>
      {!ui.showOtp && (
        <React.Fragment>
          <div className="p-9">
            <div className="  text-center items-center ">
              <button
                onClick={(e) => btnActive && onClickSubmit()}
                type="submit"
                className={
                  btnActive && !loading
                    ? "w-full bg-zyple text-white p-3 text-xl   font-semibold rounded-full"
                    : "w-full bg-[#BEBFCA] text-white p-3 text-xl font-semibold rounded-full"
                }
                disabled={loading}
              >
                Request OTP
              </button>
            </div>
          </div>

          <div className="font-bold text-base leading-6">
            SEWA Voucher code generation
            <p style={textStyle}>
              To generate voucher code for Max Life Secure Earnings and Wellness
              Advantage Plan (SEWA) (UIN:104N136V01), click below:
            </p>
            <span
              className="text-zyple underline font-semibold text-base leading-7"
              onClick={() => {
                history.push("/registervoucher");
                dispatch(
                  logEventWithData({
                    action: "generate_voucher",
                    data: { timestamp: Date.now() },
                  })
                );
              }}
            >
              Generate voucher code
            </span>
            <p className="py-5" style={style}>
              Having trouble logging in? Contact support @ 18601205577
            </p>
          </div>
        </React.Fragment>
      )}
      {ui.showOtp && (
        <React.Fragment>
          <div className="  text-gray-500">
            <div className="flex justify-center pt-12 ">
              <button
                onClick={(e) => onClickVerifyOtp(e)}
                disabled={!otp}
                type="submit"
                className={
                  otp
                    ? "w-96 bg-zyple text-white p-4 text-2xl font-semibold rounded-full"
                    : "w-96 bg-[#d8d8d8] text-white p-4 text-2xl font-semibold rounded-full"
                }
              >
                Submit
              </button>
            </div>
            <div className="items-center text-center text-xs">
              <p className="font-medium " style={{ color: "#26284D80" }}>
                I provide my consent and agree to the{" "}
                <Link
                  to={{
                    pathname: "/terms",
                    state: true,
                  }}
                  className="no-underline"
                >
                  <span
                    className=" font-medium cursor-pointer"
                    style={{ color: "#1E4679" }}
                  >
                    Terms and conditions.
                  </span>
                </Link>
              </p>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
