import React, { Fragment, useState } from "react";
import APIConfig from "../../services/APIConfig";
import DoctorCard from "./components/doctorCard";
import backImg from "../../assets/images/icon_arrow_back.png";
import { useHistory } from "react-router-dom";
import "./index.scss";
import { IonPage, IonContent } from "@ionic/react";
import avatarImg from "../../assets/images/avatar.png";
import tickimg from "../../assets/images/tick.png";
import SecureImage from "../../components/common/SecureImage";
import RippleButton from "../../components/common/RippleButton";
import BackButton from "../../components/common/BackButton";
import LoadingModal from "../../components/loading";
import DoctorCodeComponent from "../../components/doctorCodeComponent/doctorCodeComponent2";
import { Drawer } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ErrorComponent from "../../components/common/ErrorComponent";
import axiosClient from "../../app/axiosConfig";
import { updatePatientProfile } from "../login/loginSlice";
import { getHomePageData } from "../HomePage/homePageSlice";

export default function CareTeam() {
  const patientProfile = useSelector(
    (store) => store.login.user?.patientProfile
  );
  const paytmFlag = useSelector((store) => store.app.device.isPaytm);
  const careTeams = useSelector((store) => store.tab.careTeams);
  const [doctor, setDoctor] = useState({});
  const [doctorCode, setDoctorCode] = useState("");
  const [currentDoctor, setCurrentDoctor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [doctorCodeOpen, setDoctorCodeOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const onCloseDrawer = (status, result) => {
    let doctorCode = "";
    let doctor = {};

    if (status) {
      doctorCode = result.code;
      doctor = result.doctor;

      let url = APIConfig.careTeam.doctoradd.format(patientProfile.id);
      axiosClient
        .put(url, { doctorCode: doctorCode })
        .then((res) => {
          dispatch(
            updatePatientProfile({
              ...patientProfile,
              referralCode: doctorCode,
            })
          );
          dispatch(getHomePageData(patientProfile?.id));
          setDoctor(doctor);
          setDoctorCodeOpen(false);
          setDoctorCode(doctorCode);
        })
        .catch((err) => {
          setError(true);
          setErrorMsg("Error while updating doctor code.");
        });
    }
    if (!status) {
      setDoctorCodeOpen(false);
    }
  };

  const handleClick = (doctor) => {
    setCurrentDoctor(doctor);
  };

  const hideDetails = () => {
    setCurrentDoctor(null);
  };

  return (
    <IonPage>
      <IonContent>
        <ErrorComponent
          show={error}
          msg={errorMsg}
          errorClose={() => {
            setError(false);
            setErrorMsg("");
          }}
        />
        {loading ? (
          <LoadingModal></LoadingModal>
        ) : (
          <Fragment>
            {doctorCodeOpen && (
              <Drawer
                role="presentation"
                className={" signup-container menu-partial-medium"}
                anchor="bottom"
                disableEnforceFocus
                open={doctorCodeOpen}
                onClose={() => {
                  setDoctorCodeOpen(false);
                }}
              >
                <DoctorCodeComponent
                  doctorCode={doctorCode}
                  doctor={doctor}
                  closeDrawer={(status, code) => {
                    onCloseDrawer(status, code);
                  }}
                ></DoctorCodeComponent>
              </Drawer>
            )}

            {!currentDoctor ? (
              <div className="care-team-container">
                <div className="flex items-center mb-3">
                  {!paytmFlag && (
                    <div className="mr-3">
                      <BackButton history={history}></BackButton>
                    </div>
                  )}
                  <div className="care-team fw-semibold fs20">My team</div>
                </div>
                <div>
                  <div className="care-team-info fs14 fw-light">
                    <div className="intro-text-care">
                      Leading multi-disciplinary team of doctors to monitor and
                      improve your health continuously.
                      <br></br>
                      <br></br>
                      {doctorCode === "DC000000" && (
                        <b>
                          Have a Doctor Code?
                          <b
                            onClick={() => {
                              setDoctorCodeOpen(true);
                            }}
                            className="care_team_link_button"
                          >
                            Add Here
                          </b>
                        </b>
                      )}
                      {doctorCode !== "DC000000" && (
                        <div className="doctor_code_msg">
                          <img src={tickimg} alt="icon" />
                          <b>
                            Doctor Code Added,&nbsp;
                            <button
                              className="care_team_link_button"
                              onClick={() => {
                                setDoctorCodeOpen(true);
                              }}
                            >
                              Update
                            </button>
                          </b>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="doc-list">
                  {careTeams &&
                    careTeams.length > 0 &&
                    careTeams.map((doc) => {
                      return <DoctorCard handleClick={handleClick} doc={doc} />;
                    })}
                </div>
              </div>
            ) : (
              <div className="current-doctor-content">
                <div className="back-button-wrapper">
                  <RippleButton>
                    <img
                      onClick={hideDetails}
                      className="back-arrow"
                      src={backImg}
                      alt="icon"
                    />
                  </RippleButton>
                </div>

                <div className="current-doc-details">
                  <div className="current-doc-img">
                    <SecureImage
                      defaultImage={avatarImg}
                      src={currentDoctor.profile_image}
                    ></SecureImage>
                  </div>
                  <div className="fw-light fs24">{currentDoctor.name}</div>
                  <div className="fs16 doc-speciality">
                    {currentDoctor.title}
                  </div>
                  <div className="fs14 current-doc-description">
                    {currentDoctor.description}
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        )}
      </IonContent>
    </IonPage>
  );
}
