import { IonContent, IonPage } from "@ionic/react";
import Humberger from "../../../assets/images/menu.png";
import maxLogo from "../../../assets/images/max.svg";
import doctorHome from "../../../assets/images/DoctorHomeLogo.png";
import mliBannner from "../../../assets/images/mli-banner-2.png";
import { Link, useHistory } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch } from "react-redux";
import { logEvent } from "../../../app/eventSlice";

const featureCardSlickCarouselSettinngs = {
  dots: true,
  arrows: false,
  autoplay: false,
  autoplaySpeed: 2500,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export function SliderImage({ bannerData, id }) {
  const dispatch = useDispatch();
  const banners = bannerData?.map((banner) => {
    return (
      <div>
        <img
          src={banner.posterLink}
          
          onClick={() => {
           
            dispatch(logEvent({ action: `click_banner_${banner.name}` }));
            banner.link && history.push(`${banner.link}`);
          }}
        />
      </div>
    );
  });

  const history = useHistory();
  return (
    <>
      <div className="flex items-center ">
        <Link to="/tab/hamburger">
          <img src={Humberger} alt="" className="h-6 w-6 cursor-pointer" />
        </Link>
        <div className="flex justify-center m-auto">
          <img src={maxLogo} alt="" className=" w-[110px] h-[56px] m-[20px]" />
        </div>
      </div>
      <Slider
        {...featureCardSlickCarouselSettinngs}
        className="homepage-pfizer-slider "
      >
        {/* <div className=" rounded-md  flex mli-doctor-card-class">
          <img src={doctorHome} alt="" className="h-44 w-28" />
          <div
            className="pt-10 px-3 font-bold text-sm "
            style={{ color: "#143A72" }}
          >
            Avail unlimited <br />
            consultations with <br />
            general physician
            <br />
            <button
              className="mt-6 rounded-full font-bold  text-white px-3 py-1 bg-zyple text-xs"
              onClick={() => {
                history.push("/navigo?currScreen=1");
              }}
            >
              Book Now
            </button>
          </div>
        </div> */}

        {banners}
        {/* <div className="">
          <img src={mliBannner} />
        </div>
        <div className="">
          <img src={mliBannner} />
        </div> */}
      </Slider>
    </>
  );
}

// onClick={() => {
//   history.push("/navigo?currScreen=1");
// }}
