import React from "react";
import nextImg from "../../../assets/images/next_2.png";
import avatarImg from "../../../assets/images/avatar.png";

import SecureImage from "../../../components/common/SecureImage";
import RippleButton from "../../../components/common/RippleButton";
import "./doctorCard.css";

export default function DoctorCard({ doc, handleClick, index }) {
  return (
    <>
      {doc.title === "Default Dr" ? (
        ""
      ) : (
        <>
          <RippleButton
            nospace
            onClick={() => handleClick(doc)}
            className="doctor-info"
          >
            <div className="doctor-image-container">
              <SecureImage
                defaultImage={avatarImg}
                src={doc.profile_image}
              ></SecureImage>
            </div>
            <div className="doctor-description">
              <div className="fs16 fw-light">{doc.name}</div>
              <div className="doc-speciality fs12">{doc.title}</div>
              <div className="doc-desc-para description">{doc.description}</div>
            </div>
            <div>
              <img className="next-doc-icon" src={nextImg} alt="icon" />
            </div>
          </RippleButton>
        </>
      )}
    </>
  );
}
