import React, { useEffect, useState } from "react";
import "./home2.0.scss";
import homeMedicineIcon from "../../../assets/icons/home-medicine-icon.svg";
import homeDiagnosticIcon from "../../../assets/icons/home-testtube-icon.svg";
import dentalBlackIcon from "../../../assets/icons/dental-black-icon.svg";
import careTeamAvatar from "../../../assets/images/avatar.png";

import {
  getUrlWithToken,
  redirectToWebWithToken,
} from "../../../utils/helperMethods";
import { useDispatch, useSelector } from "react-redux";
import { getCareTeam } from "../../../containers/tab/tabSlice";
import { logEvent, logEventWithData } from "../../../app/eventSlice";
import { useHistory } from "react-router";

const healthServiceTiles = [
  {
    id: 1,
    title: "Medicines",
    subtitle: "Save upto 20%",
    path: "medicine",
    icon: homeMedicineIcon,
    eventName: "click_zyla_offer_medicine",
  },
  {
    id: 2,
    title: "Diagnostics",
    subtitle: "Save upto 60%",
    path: "navigo",
    icon: homeDiagnosticIcon,
    eventName: "click_zyla_offer_diagnostic",
  },
  {
    id: 3,
    title: "Dental care",
    subtitle: "Save upto 20%",
    path: "dentalCare",
    icon: dentalBlackIcon,
    eventName: "click_zyla_offer_dental",
  },
];

function ZylaOffer() {
  const patientProfile = useSelector(
    (store) => store.login.user?.patientProfile
  );
  const doctors = useSelector((store) => store.tab.careTeams);
  const clientConfig = useSelector((store) => store.tab.clientConfig);
  const isPremium = useSelector((store) => store.tab.uiConfig.isPremium);
  const isZylaZen = useSelector((store) => store.tab.uiConfig.isZylaZen);
  const [shouldShowData, setShouldShowData] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (!doctors || (doctors && doctors.length === 0)) {
      dispatch(getCareTeam());
    } else {
      let cc = patientProfile.clientCode;
      if (
        patientProfile.countryCode != 91 ||
        cc === "AZ" ||
        cc === "CC" ||
        cc === "HH"
      ) {
        setShouldShowData(false);
      } else {
        setShouldShowData(true);
      }
    }
  }, []);

  return (
    <>
      {patientProfile.countryCode == 91 && (
        <div className="zyla-offer-wrapper">
          {/* <h2>What zyla Offers</h2> */}

          {shouldShowData && doctors && (
            <div className="expert-consultation">
              <div className="expert-consultation-heading">
                <div className="expert-consultation-heading-left">
                  <p>
                    {isPremium
                      ? "Consult Expert Doctors"
                      : "Consult Sr Doctors"}
                  </p>
                </div>
                <p
                  className="expert-consultation-heading-right"
                  onClick={() => {
                    if (isZylaZen || isPremium) {
                      let type = isZylaZen ? "zen" : "premium";
                      dispatch(
                        logEventWithData({
                          action: "zyla_offer_expert_consultation_view_all",
                          data: { patient: type },
                        })
                      );
                    } else {
                      dispatch(
                        logEventWithData({
                          action: "zyla_offer_expert_consultation_view_all",
                          data: { patient: "freemium" },
                        })
                      );
                    }

                    redirectToWebWithToken(
                      process.env.REACT_APP_zyla_WEBSITE + "teleconsult",
                      patientProfile?.id
                    );
                  }}
                >
                  View All
                </p>
              </div>
              <div className="expert-consultation-doctor-list">
                {doctors &&
                  doctors.length > 0 &&
                  doctors.map((doctor) => (
                    <div className="expert-consultatnt-cards">
                      <div className="expert-consultatnt-card-wrapper">
                        <div>
                          <img
                            src={
                              doctor.profile_image
                                ? getUrlWithToken(doctor.profile_image)
                                : careTeamAvatar
                            }
                            alt="profile-img"
                            className="expert-consultant-doctor-image"
                            onErrorCapture={(e) => {
                              if (e.target.src !== careTeamAvatar) {
                                e.target.src = careTeamAvatar;
                              }
                              e.target.onerror = null;
                            }}
                          />
                          <div>
                            <h3>{doctor.name}</h3>
                            <p>{doctor.title}</p>
                          </div>
                        </div>
                        <p>{doctor.description}</p>
                      </div>
                    </div>
                  ))}
                {doctors && doctors.length > 0 && (
                  <div className="pseudo-margin"> &nbsp;</div>
                )}
              </div>
            </div>
          )}

          {/* <div className="health-services">
            <p className="genralised-black-font health-services-heading">
              Save on Health Services
            </p>

            <div className="zyla-offer-home-healthserviceTiles-wrapper">
              {healthServiceTiles.map((ele) => {
                return (
                  <div
                    key={ele.id}
                    className="zyla-offer-home-healthserviceTiles-ele"
                    onClick={() => {
                      dispatch(logEvent({ action: ele.eventName }));
                      if (ele.id === 2 && clientConfig.clientType !== "B2B") {
                        redirectToWebWithToken(
                          process.env.REACT_APP_zyla_WEBSITE +
                            "full-body-test-health-checkup?utm_source=app_launcher&utm2=app",
                          patientProfile?.id
                        );
                      } else {
                        history.push(`/${ele.path}`);
                      }
                    }}
                  >16333
                    <div className="zyla-offer-home-healthserviceTiles-ele-top">
                      <div className="zyla-offer-home-healthserviceTiles-ele-top-img-wrapper">
                        <img src={ele.icon} alt="icon" />
                      </div>
                    </div>
                    <div className="zyla-offer-home-healthserviceTiles-ele-bottom">
                      <p>{ele.title}</p>
                      <p className="zyla-offer-home-healthserviceTiles-ele-bottom-subtitle">
                        {ele.subtitle}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div> */}
        </div>
      )}
    </>
  );
}

export default ZylaOffer;
