import React, { createRef, useEffect, useState } from "react";
import "../index.scss";
import HpImage from "../../../assets/images/hp-layout.png";
import orangeArrow from "../../../assets/images/next_arrow.png";

import { IonSlides, IonSlide, IonSpinner, IonModal } from "@ionic/react";
import exerciseIcon from "../../../assets/images/introCarousel/icon_exercise.png";
import meditationIcon from "../../../assets/images/introCarousel/icon_meditation.png";
import nutritionIcon from "../../../assets/images/introCarousel/icon_nutrition.png";
import leafIcon from "../../../assets/icons/healthplan-leaf-icon.png";
import tickIcon from "../../../assets/images/tick_sign_icon.png";
import medicalIcon from "../../../assets/images/introCarousel/icon_medical.png";
import HealthPlanCard from "./healthPlanOptionsCard";

import HealthPlanSlider from "./healthPlanSlider";
import "firebase/analytics";
import "firebase/auth";
import $ from "jquery";

import ErrorBox from "../../../components/common/error-box";
import { Dialog, Drawer } from "@mui/material";
import HPLevel from "./hpLevel";
import BackButton from "../../../components/common/BackButton";
import FoodList from "./foodlist";
import Guidelines from "./guidelines";
import { useDispatch } from "react-redux";
import { logEventWithData } from "../../../app/eventSlice";

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  spaceBetween: -18,
};
export default function LevelLayoutComponent({
  healthPlan,
  isPaytm,
  ui,
  scrollTop,
}) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerItem, setDrawerItem] = useState(1);
  const [sliderData, setSliderData] = useState(null);
  const [initialSlide, setInitialSlide] = useState(0);

  const scrollWrapper = createRef();
  const dispatch = useDispatch();

  // const state = {
  //   healthPlan: {},
  //   progress: {},
  //   healthPlanCategories: [],
  //   hpCategory: {},
  //   sliderData: null,
  //   loading: true,
  //   loaded: false,
  //   hasError: false,
  //   initialSlide: 0,
  //   guidelines: [],
  // };

  const getIcon = (category) => {
    if (category === "Medical") {
      return medicalIcon;
    } else if (category === "Meditation") {
      return meditationIcon;
    } else if (category === "Exercise") {
      return exerciseIcon;
    } else if (category === "Nutrition") {
      return nutritionIcon;
    }
  };

  const setSliderDataFn = (sd, is) => {
    setSliderData(sd);
    setInitialSlide(is ? is : 0);
  };

  const closeSlider = () => {
    setSliderData(null);
  };

  const getCategoryLayout = (category) => {
    let currentCategory = [];
    for (let key in healthPlan.data) {
      if (key === category) {
        currentCategory = healthPlan.data[key].items;
      }
    }

    if (currentCategory && currentCategory.length) {
      return currentCategory.map((categoryFields, index) => {
        let realCategoryField = {
          ...categoryFields,
          catname: category,
        };

        return (
          <HealthPlanCard
            setSliderData={setSliderDataFn}
            index={index}
            categoryFields={realCategoryField}
          />
        );
      });
    }
  };

  const reorderHealthPlanCard = (healthPlan) => {
    let updatedHealthPlan = { ...healthPlan };
    if (updatedHealthPlan && updatedHealthPlan.data) {
      updatedHealthPlan.data = [];
      ["Nutrition", "Exercise", "Medical", "Meditation"].forEach((cat) => {
        if (healthPlan.data[cat]) {
          updatedHealthPlan.data.push({
            category: cat,
            values: healthPlan.data[cat].items,
          });
        }
      });
    }

    return updatedHealthPlan;
  };
  const handleScroll = () => {
    if (scrollTop > 175) {
      $(".hp-smaller-header").css("display", "flex");
    } else {
      $(".hp-smaller-header").hide();
    }
  };

  const scrollContent = () => {
    scrollWrapper.current.scrollIntoView({ behavior: "smooth" });
  };
  const navigateTo = (e) => {
    let ionSlide = e.srcElement;
    let hp = reorderHealthPlanCard(healthPlan);
    let category = hp.data.find(
      (item) => item.category === window.location.search.slice(1)
    );
    let index = hp.data.indexOf(category);
    ionSlide.slideTo(index);
  };
  const handleDrawerClick = () => {
    setOpenDrawer(false);
  };

  let hp = reorderHealthPlanCard(healthPlan);

  let hp_text;
  if (hp.current_level) {
    if (hp.current_level === "Level 1") {
      hp_text = "Share 3 Vitals to unlock level 2 health plan.";
    } else if (hp.current_level === "Level 2") {
      hp_text =
        "Share 3 reportings and take health assessment call to unlock next level.";
    } else {
      hp_text = "";
    }
  }

  handleScroll();
  return sliderData ? (
    <HealthPlanSlider
      closeSlider={closeSlider}
      sliderData={sliderData}
      initialSlide={initialSlide}
    />
  ) : ui.loading ? (
    <div className="loading-spinner-holder">
      <IonSpinner />
    </div>
  ) : (
    <div
      className="wrapper responsive-600"
      onScroll={() => {
        handleScroll();
      }}
      ref={scrollWrapper}
    >
      {ui.error && <ErrorBox />}
      <div className="hp-head-content">
        <div className="hp-layout-head">
          <div>
            {!isPaytm && (
              <div className="item-hp-head">
                <BackButton whiteButton />
              </div>
            )}
            <div className="fs20 fw-semibold item-hp-head">Health Plan</div>
            <div className="fs14">Health Plan is your daily to do list.</div>
          </div>
          <div>
            <img className="hp-layout-logo" alt="" src={HpImage} />
          </div>
        </div>
        {hp.current_level && (
          <div className="fs20 fw-semibold current-hp-level">
            <div className="current-hp-level-top">
              <span className="current-hp-level-top-first">
                {hp.current_level}
              </span>
              {!(
                hp.current_level === "Level 1" || hp.current_level === "Level 2"
              ) && (
                <div
                  className="healthplan-guideline-button-level-3"
                  onClick={() => {
                    setOpenDrawer(true);
                    setDrawerItem(2);
                  }}
                >
                  <img src={tickIcon} alt="" />
                  <span>Guidelines</span>
                </div>
              )}
            </div>

            <div className="current-hp-plan-child">
              {(hp.current_level === "Level 1" ||
                hp.current_level === "Level 2") && (
                <p className="fs14 fw-light" style={{ margin: 0, flex: 1 }}>
                  {hp_text}
                </p>
              )}
              {/* {(hp.current_level === "Level 1" ||
                hp.current_level === "Level 2") && (
                <img
                  src={orangeArrow}
                  alt=""
                  style={{
                    width: "20px",
                    height: "20px",
                    marginLeft: "10px",
                  }}
                  onClick={() => {
                    setDrawerItem(1);
                    setOpenDrawer(true);
                  }}
                />
              )} */}
            </div>
          </div>
        )}
      </div>
      <div className="hp-smaller-header">
        {!isPaytm && <BackButton whiteButton />}
        <span className="header-text">{hp.current_level}</span>
        <p className="header-text">Health Plan</p>
      </div>

      <div className="hp-body healthPlanSlide">
        {hp && hp.data && (
          <IonSlides
            className="hide-bullet healthPlanSlide"
            pager={true}
            options={slideOpts}
            onIonSlideDidChange={(e) => {
              try {
                e.srcElement
                  .getActiveIndex()
                  .then((res) => {
                    dispatch(
                      logEventWithData({
                        action: `view_health_plan_slide_${hp.data[res].category}`,
                      })
                    );
                  })
                  .catch((err) => {
                    console.log("error is ", err);
                  });
              } catch (err) {}
              scrollContent();
            }}
            onIonSlidesDidLoad={(e) => {
              navigateTo(e);
            }}
          >
            {hp.data.map((data, index) => {
              return (
                <IonSlide>
                  <div className="category-slider">
                    <div className="fw-semibold category-head">
                      <div className="category-head-left">
                        <div>
                          <img
                            alt=""
                            className="hp-icon"
                            src={getIcon(data.category)}
                          />
                        </div>
                        <div>
                          <div className="hp-category-name">
                            {data.category.toLowerCase()}
                          </div>
                        </div>
                      </div>
                      {hp.current_level !== "Level 1" &&
                        hp.current_level !== "Level 2" &&
                        data.category === "Nutrition" && (
                          <div
                            className="category-head-right"
                            onClick={() => {
                              setOpenDrawer(true);
                              setDrawerItem(3);
                            }}
                          >
                            <img src={leafIcon} alt="" />
                            <span>Food list</span>
                          </div>
                        )}
                    </div>
                    <div className="hp-body-slider">
                      {getCategoryLayout(data.category)}
                    </div>
                    {data.category !== "Meditation" &&
                      hp.current_level === "Level 2" && (
                        <div className="source-container-div">
                          {/* <span className="source-container-heading">
                            Sources:
                          </span> */}
                          {data.category === "Nutrition" && (
                            <div className="source-container-links">
                              <span className="source-container-heading">
                                Sources:
                              </span>
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://www.diabetes.org/"
                              >
                                ADA,
                              </a>
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://www.nin.res.in/"
                              >
                                NIN,
                              </a>
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href="http://idaindia.com/"
                              >
                                IDA,
                              </a>
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://food-guide.canada.ca/en/"
                              >
                                CDG
                              </a>
                            </div>
                          )}
                          {data.category === "Exercise" && (
                            <div className="source-container-links">
                              <span className="source-container-heading">
                                Sources:
                              </span>
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://www.diabetes.org/"
                              >
                                ADA,
                              </a>
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://pubmed.ncbi.nlm.nih.gov/"
                              >
                                PubMed,
                              </a>
                              <span className="exercise-text">
                                Textbooks by O'Sullivan Schmitz, Kisner Kolby,
                                Patricia A Downing, GB Madhuri, S Sunder
                              </span>
                            </div>
                          )}
                          {data.category === "Medical" && (
                            <div className="source-container-links">
                              <span className="source-container-heading">
                                Sources:
                              </span>
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://www.nhs.uk/"
                              >
                                NHS,
                              </a>
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://www.heart.org/"
                              >
                                AHA,
                              </a>
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://www.diabetes.org/"
                              >
                                ADA
                              </a>
                            </div>
                          )}
                        </div>
                      )}
                  </div>
                </IonSlide>
              );
            })}
          </IonSlides>
          //
        )}
        {!hp.current_level && (
          <div id="hp-unavailable">
            Looks like we need to understand more about your health to customize
            the best plan! Please book your health risk assessment call from the
            homepage to recieve a customised health plan.
          </div>
        )}
      </div>
      <IonModal isOpen={openDrawer}>
        {drawerItem === 1 && (
          <HPLevel
            handleDrawerClick={handleDrawerClick}
            hp={healthPlan}
            txt={hp_text}
          />
        )}
        {drawerItem === 3 && <FoodList handleDrawerClick={handleDrawerClick} />}
        {drawerItem === 2 && (
          <Guidelines
            handleDrawerClick={handleDrawerClick}
            guidelines={healthPlan?.data?.Nutrition?.Guidlines}
          />
        )}
      </IonModal>
    </div>
  );
}
