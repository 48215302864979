import { IonDatetime } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import arrowLeft from "../../../assets/icons/arrow-back-black.svg";
import profileIcon from "../../../assets/icons/profile-icon.svg";
import emailIcon from "../../../assets/icons/email-icon.svg";
import lockIcon from "../../../assets/icons/lockIcon.png";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import {
  isValidEmail,
  processUplodedFile,
} from "../../../utils/helperMethods.js";

import SecureImage from "../../../components/common/SecureImage";
import { useHistory, useLocation } from "react-router";
import {
  addFamilyMember,
  addMemberMinor,
  updateErrorState,
  updateLayout,
  updateSinglePropertyUI,
  uploadProfilePic,
} from "../familySlice";
import { useDispatch, useSelector } from "react-redux";
import PrepareUploadService from "../../../services/PrepareUploadService.js";
function FamilyProfile({ closeDialog }) {
  const familyStore = useSelector((store) => store.familyInfo);
  const [image, setImage] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFname] = useState("");
  const [lastName, setLname] = useState("");
  const [dob, setDob] = useState("");
  const [relation, setRelation] = useState("");
  const [gender, setGender] = useState(1);
  const [loading, setLoading] = useState(true);
  const [showgender, setShowGender] = useState(-1);

  const inputRef = useRef(null);
  const requestId = useRef(null);

  const patientImageRef = useRef(profileIcon);
  const patientImageBlob = useRef(null);
  const calendarRef = useRef(null);
  const maxDateRef = useRef(moment().format("YYYY-MM-DD"));
  const minDateRef = useRef(moment().subtract(1, "d").format("YYYY-MM-DD"));
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { relations, ui } = familyStore;
  const { data, loadingProfile, currentMember } = ui;
  useEffect(() => {
    if (currentMember) {
      setFname(currentMember.firstName);
      setLname(currentMember.lastName);
      setEmail(currentMember.email);
      setImage(currentMember.profilePicURL);
      setDob(currentMember.dateOfBirth);
      setGender(currentMember.gender);
      let relation = relations.entities[currentMember.relation];
      setRelation(relation.value);
    } else {
      handleDateRefs();

      const {
        memberFirstName,
        memberLastName,
        memberGender,
        memberEmail,
        profilePicURL,
        dateOfBirth,
        memberRelation,
      } = data;

      let relation = relations.entities[memberRelation];
      let rel = relation;
      memberFirstName && setFname(memberFirstName);
      memberLastName && setLname(memberLastName);
      memberEmail && setEmail(memberEmail);
      profilePicURL && setImage(profilePicURL);
      dateOfBirth &&
        setDob(moment(dateOfBirth, "YYYY-MM-DD").format("YYYY-MM-DD"));
      setRelation(rel.value);
      setGender(
        rel.gender === 0 ? (memberGender === 0 ? -1 : memberGender) : rel.gender
      );
      setShowGender(rel.gender === 0 && memberGender === 0 ? 0 : 1);
      requestId.current = data.id;
    }
    setLoading(false);
  }, [currentMember]);

  const handleDateRefs = () => {
    if (data.memberRelation === 6 || data.memberRelation === 8) {
      minDateRef.current = moment()
        .subtract(18, "years")
        .add(1, "day")
        .format("YYYY-MM-DD");
      maxDateRef.current = moment().format("YYYY-MM-DD");
    } else {
      minDateRef.current = moment().subtract(100, "years").format("YYYY-MM-DD");
      maxDateRef.current = moment().subtract(18, "years").format("YYYY-MM-DD");
      if (data.memberRelation === 10) {
        maxDateRef.current = moment().format("YYYY-MM-DD");
      }
    }
  };
  const handleIonDateTimeOpener = () => {
    if (calendarRef && calendarRef.current) {
      if (!calendarRef.current.value) {
        const defaultDate = moment("2000-01-01").format("YYYY-MM-DD");
        calendarRef.current.value = defaultDate;
        setDob(moment(defaultDate).format("Do MMMM YYYY"));
      }

      calendarRef.current.open();
    }
  };
  const handleDateChanged = (e) => {
    let dob = e.target.value;

    if (dob.length === 10) {
    } else if (dob.length === 8) {
      dob = moment(dob, "YYYY-MM-DD");
    } else {
      dob = moment(dob).format("YYYY-MM-DD");
    }
    calendarRef.current.value = dob;
    setDob(dob);
  };
  const handleFirstName = (e) => {
    setFname(e.target.value);
  };
  const handleLastName = (e) => {
    setLname(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleError = (msg = "Something went wrong.") => {
    dispatch(updateErrorState({ data: true, msg: msg }));
  };

  const handleSaveFamilyMember = () => {
    dispatch(
      updateSinglePropertyUI({ property: "loadingProfile", value: true })
    );

    if (!isValidEmail(email)) {
      handleError("Please enter a valid email.");

      return;
    }
    if (gender === -1) {
      handleError("Please select a gender");

      return;
    }

    let body = {
      firstName: firstName,
      lastName: lastName,
      gender: gender,
      email: email.trim(),
      relation: parseInt(data.memberRelation),
      countryCode: parseInt(data.memberCountryCode),
      dateOfBirth: moment(dob, "YYYY-MM-DD").format("YYYY-MM-DD"),
    };

    if (data.memberRelation === 6 || data.memberRelation === 8) {
      dispatch(addMemberMinor(body))
        .unwrap()
        .then((res) => {
          uploadImageOnserver(res.data);
        })
        .catch((err) => {});
    } else {
      body.requestId = requestId.current;
      dispatch(addFamilyMember(body))
        .unwrap()
        .then((res) => {
          uploadImageOnserver(res.data);
        })
        .catch((err) => {});
    }
  };
  const handleProfileUpdate = () => {
    if (window.Capacitor.platform !== "web") {
      PrepareUploadService.selectFiles(false)
        .then((res) => {
          let file = null;
          if (
            res.fileResponse.target.files &&
            res.fileResponse.target.files.length > 0
          ) {
            if (res.errors && res.errors.sizeError) {
              setLoading(false);
              handleError("Only Image of size less than 20 mb is allowed");
              return;
            }
            handleImageUpload(res.fileResponse, true);
          } else {
            setLoading(false);
            handleError("Something went wrong.");
          }
        })
        .catch((err) => {
          setLoading(false);
          handleError("Something went wrong.");
        });
    } else {
      if (inputRef.current) {
        inputRef.current.click();
      }
    }
  };
  const handleImageUpload = (e, isAndroid = false) => {
    let file = e.target.files[0];
    if (isAndroid) {
      file = file.file;
    }
    setLoading(true);
    processUplodedFile(file)
      .then((res) => {
        if (res && res.result) {
          patientImageRef.current = URL.createObjectURL(res.blob);
          patientImageBlob.current = {
            blob: res.blob,
            name: file.name,
          };
          setImage(false);
          setLoading(false);
        } else {
          setLoading(false);
          handleError("Something went wrong.");
        }
      })
      .catch((errObj) => {
        if (errObj && errObj.result === false) {
          setLoading(false);
          handleError(errObj.msg);
        }
      });
  };
  const uploadImageOnserver = (res) => {
    if (patientImageBlob.current) {
      dispatch(
        uploadProfilePic({
          file: patientImageBlob.current.blob,
          fileName: patientImageBlob.current.name,
          pId: res.memberPatientId,
        })
      );
    }
    closeDialog(true, res);
  };
  const handleGoBack = () => {
    const { state } = location;
    if (state && state.isPath) {
      history.replace({
        pathname: state.path,
        state: { isMemberSelected: false, memberData: {} },
      });
    } else {
      dispatch(updateLayout(1));
    }
  };
  return loading ? (
    <CircularProgress className="ion-router-circular-progress "></CircularProgress>
  ) : (
    <div className="edit-family-profile-container">
      <div className="edit-family-profile-header">
        <img src={arrowLeft} onClick={handleGoBack} alt="icon" />
      </div>
      <div className="edit-family-profile-middle">
        <div className="edit-family-profile-middle-profilepic">
          {image ? (
            <div className="edit-family-profile-image">
              <SecureImage src={image}></SecureImage>
              <button onClick={() => handleProfileUpdate()}>edit</button>
            </div>
          ) : (
            <div className="edit-family-profile-no-image">
              <img
                src={patientImageRef.current}
                alt="profile"
                className={`${
                  patientImageBlob.current
                    ? "edit-family-profile-no-image-1"
                    : "edit-family-profile-no-image-2"
                }`}
              />
              <button onClick={() => inputRef.current.click()}>
                {patientImageBlob.current ? "edit" : "Upload"} <br />
                {patientImageBlob.current ? "" : "Picture"}
              </button>
            </div>
          )}
          <input
            type="file"
            hidden={true}
            ref={inputRef}
            accept={"image/*"}
            onChange={handleImageUpload}
          />
        </div>
        <div className="edit-family-profile-middle-fname edit-profile-common-section">
          <label>
            First name <span>*</span>
          </label>
          <input
            type="text"
            placeholder="First name"
            value={firstName}
            onChange={handleFirstName}
          />
        </div>
        <div className="edit-family-profile-middle-lname edit-profile-common-section">
          <label>
            Last name<span>*</span>
          </label>
          <input
            type="text"
            placeholder="Last name"
            value={lastName}
            onChange={handleLastName}
          />
        </div>
        <div className="edit-family-profile-middle-relation edit-profile-common-section">
          <label>
            Relation<span>*</span>
          </label>
          <div className="edit-common-group-div">
            <input type="text" value={relation} disabled />
            <img src={lockIcon} alt="icon" />
          </div>
        </div>

        {showgender === 0 && (
          <div className="edit-family-profile-middle-gender">
            <label>
              Gender<span>*</span>
            </label>
            <div className="edit-profile-middle-gender-option-container">
              <div
                className={`edit-family-profile-middle-gender-option ${
                  gender === 1 &&
                  "edit-family-profile-middle-gender-option-selected"
                } `}
                onClick={() => {
                  setGender(1);
                }}
              >
                <span>Female</span>
              </div>
              <div
                className={`edit-family-profile-middle-gender-option ${
                  gender == 2 &&
                  "edit-family-profile-middle-gender-option-selected"
                }`}
                onClick={() => {
                  setGender(2);
                }}
              >
                <span>Male</span>
              </div>
            </div>
          </div>
        )}
        <div className="edit-family-middle-dob edit-profile-common-section">
          <label>
            Date of birth<span>*</span>
          </label>
          <div className="edit-common-group-div">
            <IonDatetime
              value={dob}
              mode="ios"
              onIonChange={handleDateChanged}
              displayFormat="DD/MMM/YYYY"
              monthShortNames="Jan, Feb, Mar, Apr, May, June, July, Aug, Sep, Oct, Nov, Dec"
              placeholder="10/10/1980"
              max={maxDateRef.current}
              min={minDateRef.current}
              ref={calendarRef}
            />
            <img src={emailIcon} alt="icon" onClick={handleIonDateTimeOpener} />
          </div>
        </div>

        <div className="edit-family-middle-email edit-profile-common-section">
          <label>Email</label>

          <input
            type="text"
            placeholder="abc@xyz.com"
            value={email}
            onChange={handleEmail}
          />
        </div>
      </div>

      <div className="edit-family-last">
        <button
          onClick={handleSaveFamilyMember}
          disabled={
            firstName.length === 0 ||
            lastName.length === 0 ||
            dob.length === 0 ||
            gender === -1 ||
            loadingProfile
          }
        >
          Save
        </button>
        <p>
          By clicking save, you agree to our
          <br />
          <strong
            onClick={() => {
              history.push("/terms");
            }}
          >
            Terms of service
          </strong>
          &nbsp; & &nbsp;
          <strong
            onClick={() => {
              history.push("/privacy");
            }}
          >
            Privacy policy.
          </strong>
        </p>
      </div>
    </div>
  );
}
export default FamilyProfile;
