import React, { Component } from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import avatarImg from "../../assets/images/avatar.png";
import SecureImage from "../common/SecureImage";
export default class DoctorCard extends Component {
  render() {
    const { doctor } = this.props;

    return (
      <div className="doctor-loading">
        <div className="doctor-loading-container">
          <div className="doctor-loading-container-row">
            <div className="doctor-loading-container-col-1">
              <SecureImage
                classN="doc-profile-image"
                src={doctor.profile_image}
                defaultImage={avatarImg}
              ></SecureImage>
            </div>
            <div className="doctor-loading-container-col-2">
              <div className="doctor-title">{doctor.name}</div>

              <div className="doctor-subtitle">{doctor.speciality}</div>

              <small>{doctor.clinicHospital}</small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
