import React, { useEffect, useRef, useState } from "react";
import ReportingTable from "./components/reportingTable";

import { useHistory } from "react-router-dom";
import "./reporting.scss";

import {
  IonPage,
  IonContent,
  IonLoading,
  useIonViewDidEnter,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import ReportingCompleteSuccess from "./components/reportingCompleteSuccess";
import NextImage from "../../assets/images/next.png";
import ReportingMessage from "./components/reportingMessage";
import moment from "moment";
import RippleButton from "../../components/common/RippleButton";
import BackButton from "../../components/common/BackButton";
import { App } from "@capacitor/app";
import { Keyboard } from "@capacitor/keyboard";
import { Device } from "@capacitor/device";
import { logEvent } from "../../app/eventSlice";
import ErrorComponent from "../../components/common/ErrorComponent";
import {
  reportingShowError,
  reportingClearError,
  getReportByDate,
  handleAddLineDrawer,
  saveReport,
  toggleSubmitted,
  updateItemsFromSavedReports,
  tableErrorHandle,
  updateReportingId
} from "./reportSlice";
import { isValidDayNight, isValidHour, isValidMinute } from "./utilityMethods";
import AddLineTutorial from "./components/addLineTutorial";
import { sendMessage } from "../chat/chatSlice";
import { toggleScrollChat } from "../tab/tabSlice";

export default function ReportingContainer() {
  const patientProfile = useSelector(
    (store) => store.login.user.patientProfile
  );
  const chat = useSelector((state) => state.tab.chat);
  const ui = useSelector((store) => store.reporting.ui);
  const { loaded, loading, error, errorMsg, openDrawer, items, id } =
    ui;
  const reports = useSelector((store) => store.reporting.reports);
  const [submitted, setSubmitted] = useState(false);
  const paytmFlag = useSelector((store) => store.app.device.isPaytm);
  const platform = useSelector((store) => store.app.device.platform);
  const isOffline = useSelector((store) => store.tab.ui.isOffline);
  const [keyboardOpen, setKeyboardOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment());
  const yesterDay = useRef(moment().subtract(1, "day").format());

  const currentDate = useRef(selectedDate);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if(patientProfile){
      getAndSaveReportHelper(selectedDate);
    }
  
   
    if (platform !== "web") {
      Keyboard.addListener("keyboardDidShow", (info) => {
        setKeyboardOpen(true);
      });
      Keyboard.addListener("keyboardDidHide", (info) => {
        setKeyboardOpen(false);
      });
    }
  }, []);
  useIonViewDidEnter(() => {
    dispatch(logEvent({ action: "open_reporting" }));
  }, []);

  const getAndSaveReportHelper = (date) => {
    let formattedDate = moment(date).format("DD-MM-YYYY");

    if (reports[formattedDate]) {
      dispatch(updateItemsFromSavedReports({ data: reports[formattedDate] }));
    } else {
      dispatch(
        getReportByDate({
          date: formattedDate,
          patientId: patientProfile.id,
        })
      );
    }
  };

  const _renderDate = (timestamp) => {
    return moment(timestamp).format("Do MMMM");
  };
  const onClickChangeDate = (date) => {
    dispatch(logEvent({ action: "change_date_reporting" }));
    setSelectedDate(date);
    getAndSaveReportHelper(date);
  };
  const _createHeaderButtons = () => {
    const buttons = [
      {
        text: "Yesterday",
        isActive: moment(selectedDate).isSame(yesterDay.current),
        date: yesterDay.current,
      },
      {
        text: "Today",
        isActive: moment(selectedDate).isSame(currentDate.current),
        date: currentDate.current,
      },
    ];

    return (
      <div className="subheader">
        {buttons.map((value) => {
          const btnClassName = value.isActive ? "button-active" : "";
          return (
            <button
              onClick={(e) => onClickChangeDate(value.date)}
              className={btnClassName}
            >
              <RippleButton>
                <div style={{ "font-size": "14px" }}>{value.text}</div>
                <div className="small-text">{_renderDate(value.date)}</div>
              </RippleButton>
            </button>
          );
        })}
      </div>
    );
  };

  const onSubmitReport = () => {
    dispatch(logEvent({ action: "send_reporting" }));
    const errorIndex = 0;
    let allItems = items.filter((item) => {
      return !(
        item.hour === "" &&
        item.minute === "" &&
        item.dayNight === "" &&
        item.activity === ""
      );
    });

    let isValidToSubmit = allItems.length > 0;
    let isBlank = allItems.length === 0;
    for (let index = 0; index < allItems.length; index++) {
      let item = allItems[index];
      if (
        item.activity !== "" &&
        isValidHour(item.hour) &&
        isValidMinute(item.minute) &&
        isValidDayNight(item.dayNight)
      ) {
        if (item.hour === "" || (item.hour < 1 && item.hour))
          if (item.hour.length === 1) {
            item.hour = "0" + item.hour;
          }
        if (item.minute.length === 1) {
          item.minute = "0" + item.minute;
        }
        allItems[index] = {
          ...item,
          time: item.hour + ":" + item.minute + " " + item.dayNight,
        };
      } else {
        isValidToSubmit = false;
        errorIndex = index;
        break;
      }
    }

    if (!isValidToSubmit) {
      dispatch(logEvent({ action: "throw_error_reporting" }));
      if (isBlank) {
        dispatch(
          tableErrorHandle({
            value: true,
            msg: `Please fill at least one Time & Activity.`,
          })
        );
      } else {
        dispatch(
          reportingShowError({
            msg: `Please fill valid values for Time & Activity in ${errorIndex} row`,
          })
        );
      }
      return;
    }

    const data = {
      data: [
        {
          data: allItems,
          date: moment(selectedDate).format("DD-MM-YYYY"),
        },
      ],
      patientId: patientProfile.id,
      // _id: id,
    };
    if (id) {
      data._id = id;
    }

    dispatch(saveReport({ body: data }))
      .unwrap()
      .then((res) => {
        let message = {
          type: 6,
          text: "Reporting sumitted",
          metadata: {
            reportingDate: moment(selectedDate).format("DD-MM-YYYY"),
          },
          id: "" + new Date().getTime() + Math.random(),
        };
        dispatch(
          sendMessage({
            content: {
              message: message,
            },
            roomId: chat.chatRoom,
            userId: chat.externalUserIdSt,
          })
        );
        dispatch(updateReportingId(res.data._id))
        dispatch(logEvent({ action: "routine_reporting_submit_successful" }));
        setSubmitted(true);
        dispatch(toggleScrollChat({ value: true }));

        if (window.location.search) {
          setTimeout(() => {
            setSubmitted(false);
            
            history.goBack(-3);
          }, 3000);
        } else {
          let ex = setTimeout(() => {
            setSubmitted(false);
           
            history.goBack(-2);
          }, 3000);
        }
      })
      .catch((err) => {
        dispatch(
          reportingShowError({
            msg: isOffline ? "No internet connection" : "Something went wrong",
          })
        );
        console.log("error is catched", err);
      });
  };

  return (
    <IonPage className="reporting-container">
       <IonContent>
      {loading && loaded && (
        <IonLoading isOpen={loading} message={"Submitting"} duration={5000} />
      )}

      
    
        
          <ErrorComponent
            show={error}
            msg={errorMsg}
            errorClose={() => {
              dispatch(reportingClearError());
            }}
          />
          <header>
            <div className="reporting-header">
              <div className="title">Routine Reporting</div>
              {!paytmFlag && (
                <div className="close-icon">
                  <BackButton history={history} isClose></BackButton>
                </div>
              )}
            </div>
            {_createHeaderButtons()}
          </header>
          <ReportingMessage items={items} />
          <div className="reporting-page-main-section">
            <ReportingTable
              items={items}
              keyboardOpen={keyboardOpen}
            ></ReportingTable>
            <p
              className="reporting__add__new__line"
              onClick={() => dispatch(handleAddLineDrawer({ value: true }))}
            >
              How to add a new line ?
            </p>
            {openDrawer && <AddLineTutorial />}
          </div>
          {items && items.length > 0 && (
            <button
              className={keyboardOpen ? "submit-btn" : "submit-btn"}
              onClick={(e) => onSubmitReport()}
            >
              <RippleButton nospace>
                <img src={NextImage} alt="icon"></img>
                <label className={keyboardOpen ? "hide" : "show"}>SEND</label>
              </RippleButton>
            </button>
          )}
           {submitted &&<ReportingCompleteSuccess
          open={submitted}
          date={_renderDate(selectedDate)}
          onClose={()=>setSubmitted(false)}
        />}
        
      
      </IonContent>
    </IonPage>
  );
}
