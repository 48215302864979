import React from "react";

import MeditationVideoCard from "./meditationVideoCard";
import MedDrawer from "./medDrawer";
export default function MediaComponent({
  media,
  isPlaying,
  onPrevious,
  onNext,
  togglePlay,
  isNext,
  isPrevious,
  token,
  isRecomShow,
}) {
  return isPlaying ? (
    <MedDrawer
      media={media}
      isPlaying={isPlaying}
      onPrevious={onPrevious}
      onNext={onNext}
      togglePlay={togglePlay}
      isNext={isNext}
      isPrevious={isPrevious}
      token={token}
      isRecomShow={isRecomShow}
    />
  ) : (
    <MeditationVideoCard
      togglePlay={() => togglePlay(media)}
      media={media}
      isRecomShow={isRecomShow}
      isFromMeditation={true}
    />
  );
}
