import { CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { IonContent, IonPage } from "@ionic/react";
import AddFamilyMember from "./components/AddFamilyMember";
import "./index.scss";
import FamilyProfileOverview from "./components/FamilyProfileOverview";
import FamilyProfile from "./components/FamilyProfile";
import BackButton from "../../components/common/BackButton";
import NoFamilyAdded from "./components/noFamilyAdded";
import FamilyDashboard from "./components/familyDashboard";
import ErrorComponent from "../../components/common/ErrorComponent";
import {
  getFamilyData,
  getFamilyRelations,
  updateErrorState,
  updateLayout,
  updateLayoutAndLoading,
} from "./familySlice";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

export default function FamilyPlan() {
  const familyStore = useSelector((store) => store.familyInfo);

  const { ui, fetchingFamily, fetchingRelations } = familyStore;
  const { loading, error, errorMsg, layout, isData, isErrorPositive } = ui;
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (fetchingFamily && fetchingRelations) {
      const { state } = location;
      if (state && state.isPath) {
        dispatch(updateLayoutAndLoading({ layout: 4, loading: false }));
      } else {
        dispatch(
          updateLayoutAndLoading({
            layout: layout === 2 ? 2 : 1,
            loading: false,
          })
        );
      }
    } else {
      if (!fetchingFamily) {
        dispatch(getFamilyData());
      }
      if (!fetchingRelations) {
        dispatch(getFamilyRelations());
      }
    }
  }, [fetchingFamily, fetchingRelations, dispatch, location]);

  const handleErrorClose = () => {
    dispatch(updateErrorState({ data: false, msg: "" }));
  };

  const closeDialog = (isNewData = false, data) => {
    const { state } = location;
    if (state && state.isPath) {
      let memberData = {};
      let isMemberSelected = false;
      if (isNewData) {
        memberData = { ...data };
        isMemberSelected = true;
      }
      history.replace({
        pathname: state.path,
        state: { isMemberSelected: isMemberSelected, memberData: memberData },
      });
      return;
    } else {
      dispatch(updateLayout(1));
    }
  };

  return (
    <IonPage>
      <IonContent>
        {layout === 1 && (
          <div className="family-plan-container">
            <div className="family-plan-header">
              <BackButton></BackButton>
              <span>Family members</span>
            </div>
            {loading ? (
              <CircularProgress className="ion-router-circular-progress"></CircularProgress>
            ) : (
              <div className="family-plan-loaded">
                {isData ? <FamilyDashboard /> : <NoFamilyAdded />}
              </div>
            )}
          </div>
        )}
        {layout === 2 && <FamilyProfileOverview />}
        {layout === 3 && <FamilyProfile closeDialog={closeDialog} />}
        {layout === 4 && <AddFamilyMember closeDialog={closeDialog} />}
        <ErrorComponent
          msg={errorMsg}
          show={error}
          isPositive={isErrorPositive}
          errorClose={handleErrorClose}
        />
      </IonContent>
    </IonPage>
  );
}
