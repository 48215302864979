import { Link } from "react-router-dom";
import forwardImage from "../../../assets/images/forwardRed.png";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { rewardData } from "../../zylaWallet/rewardPages/rewardSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";

export function MyRewardPoint() {
  const data = useSelector((state) => state.reward?.data);
  const cidrReward = data?.data?.cidrRewards?.cidr;
  const cidrLength = cidrReward ? Object.values(cidrReward).length : 0;
  const sewaReward = data?.data?.sewaRewards?.sewa;
  const sewLength = sewaReward ? Object.values(sewaReward).length : 0;
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const patientProfile = useSelector(
    (state) => state.login?.user?.patientProfile
  );
  const [Open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearTimeout(timer);
  };
  const timer = setTimeout(() => {
    handleClose();
  }, 5000);

  return (
    <>
      <div className=" flex justify-between text-lightblack pr-3 mt-12 ">
        <div className="font-bold text-base leading-5">My rewards</div>

        <img
          src={forwardImage}
          className="w-6 h-6 cursor-pointer"
          onClick={() => {
            setLoading(true);
            dispatch(rewardData(patientProfile.id))
              .unwrap()
              .then((res) => {
                setLoading(false);
                history.push("/rewardPointPage");
              })
              .catch((err) => {
                setLoading(false);
                handleOpen();
              });
          }}
        />
      </div>
      <div className="font-semibold  text-xs leading-4 text-lightblack pr-4 mt-1">
        Earn rewards for every step you take towards getting healthy.
      </div>

      <div>
        <Dialog open={Open} onClose={handleClose}>
          <DialogTitle>
            <span className="text-lightblack font-semibold text-base leading-7">
              Try again later
            </span>
          </DialogTitle>
          <DialogContent>
            <span className="text-lightblack font-normal text-xs leading-5">
              Due to some technical error unable to open “My rewards” page right
              now, pls try again later.
            </span>
          </DialogContent>
          <DialogActions>
            <div onClick={handleClose}>
              <span className="text-lightblack font-semibold text-xs leading-4">
                Go back
              </span>
            </div>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog open={loading} onClose={() => {}}>
          <DialogActions>
            <CircularProgress />
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
